import React, {useEffect, useState} from "react";
import { API } from "aws-amplify";
import {Button, Card, Col, Divider, Layout, Popconfirm, Row, Table, Tag, Typography} from "antd";
import {
	ExclamationCircleTwoTone,
	CheckCircleTwoTone,
	SyncOutlined
} from '@ant-design/icons';
import {useHistory} from "react-router";
import {getUidFromCookie} from "../../helpers/getUserId";

const { Content, Footer } = Layout;
const { Title } = Typography;

const Priceplan: React.FC = () => {
	const [queryLoading, setQueryLoading] = useState(false);
	const [stocksData, setStocksData] = useState([]);
	const [loadingData, setLoadingData] = useState(false);
	const [viewId, setViewId] = useState('');
	const [modalKey, setModalKey] = useState('');
	const history = useHistory();

	useEffect(() => {
		refreshData();
	}, []);

	function refreshData() {
		setLoadingData(true);
		queryProducts().then(response => {
			// console.log(response);
			var payload = JSON.parse(response.responseBody.Payload);
			if (payload.success) {
				// console.log(payload.body);
				const body = JSON.parse(payload.body);
				setStocksData(body);
			}
			setLoadingData(false);
		});
		setQueryLoading(false);
	}

	async function queryProducts() {
		let myInit = {
			headers: {
				'X-UID': getUidFromCookie()
			}
		};
		return await API.get('pejuangweb', '/api/priceplan/', myInit);
	}

	const modalColumns = [
		{
			title: 'Priceplan ID',
			dataIndex: 'id',
			sorter: (a: { id: string; }, b: { id: string; }) => a.id.localeCompare(b.id),
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			defaultSortOrder: 'descend'
		},
		{
			title: 'Priceplan Name',
			dataIndex: 'name',
			sorter: (a: { name: string; }, b: { name: string; }) => a.name.localeCompare(b.name),
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
		},
		{
			title: 'Action',
			key: 'action',
			render: (text: any, record: any) => (
				renderActionButton(record)
			),
		},
	];

	function renderActionButton(record : any){
		return <span>
                <Button style={{marginRight: 10}} onClick={() => handleViewButton(record)}>View</Button>
            </span>
	}

	function handleViewButton(record : any) {
		setViewId(record.id);
		// console.log (record.id);
		history.push("/priceplan/" + record.id);

	}


	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Content className="pejuang-main-content">
				<Row>
					<Col span={24}>
						<Card bordered={false} className="header-background">
							<Title level={4}>Priceplan</Title>
						</Card>
					</Col>
				</Row>


				<Row>
					<Col span={24} className="content-layout">
						<Card>
							<Row>
								<Col span={6} push={18} style={{textAlign: "right"}}>
									<Button type="default" onClick={() => refreshData()}  icon={<SyncOutlined />}>
										Refresh
									</Button>
								</Col>
							</Row>
							<br/>
							<Table
								loading={loadingData}
								bordered
								scroll={{x: true}}
								dataSource={stocksData}
								// @ts-ignore
								columns={modalColumns}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		</Layout>
	);
};

export default Priceplan
