import {Button, Card, Col, Input, Layout, Modal, PageHeader, Popconfirm, Row, Table, Typography} from 'antd';
import React, {useEffect, useState} from "react";
import 'react-tabulator/lib/styles.css';
import "react-tabulator/lib/css/semantic-ui/tabulator_semantic-ui.min.css";
import {reactFormatter, ReactTabulator} from "react-tabulator";
import {API, Auth} from "aws-amplify";
import {getUidFromCookie} from "../../helpers/getUserId";

const {Search} = Input;
const _ = require('lodash');
const { Content, Footer } = Layout;
const { Title } = Typography;

type RefundBalanceProps = {}

const RefundTransaction: React.FC<RefundBalanceProps> = (props) => {
    const [listRefundBalance, setListRefundBalance] = useState([]);
    const [deleteData, setDeleteData] = useState({});
    const [submitLoading, setSubmitLoading] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    const [resultModalVisibility, setResultModalVisibility] = useState(false);
    const [resultData, setResultData] = useState([]);

    useEffect(() => {
        var newSelectedTransactionId: never[] = [];
        for (var i = 0; i < listRefundBalance.length; i++) {
            // @ts-ignore
            if (listRefundBalance[i]["id"] !== deleteData["id"]) {
                // @ts-ignore
                newSelectedTransactionId.push(listRefundBalance[i]);
            }
        }
        setListRefundBalance(newSelectedTransactionId);
    }, [deleteData]);


    function addRefundBalance(value: any) {
        let listRefunds = JSON.parse(JSON.stringify(listRefundBalance));

        if (!listRefunds.some((item: any) => item.id === value)) {
            setAddLoading(true);
            getTransactionsIds(value).then(data => {
                let payload = JSON.parse(data.responseBody.Payload);
                if (payload.success) {
                    var body = JSON.parse(payload.body);
                    // console.log(body);
                    listRefunds.push({
                        id: body.transactionId,
                        phoneNumber: body.phoneNumber,
                        productId: body.productId,
                        price: body.price
                    });
                    setListRefundBalance(listRefunds);
                } else {
                    let errorMessage = payload.body;
                    Modal.error({
                        title: 'Error',
                        content: errorMessage,
                    });
                }
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                setSubmitLoading(false);
                setAddLoading(false);
            })

        }
    }

    function getModalColumns() {
        return [
            {title: "TransactionID", field: "id"},
            {title: "Phone Number", field: "phoneNumber"},
            {title: "ProductId", field: "productId"},
            {title: "Price", field: "price"},
            {
                title: "Action",
                field: "action",
                align: "center",
                formatter: reactFormatter(<DeleteButtonFormatter/>)
            }
        ];
    }

    function getResultModalColumns() {
        return [
            {title: "ResellerId", field: "resellerId"},
            {
                title: "Balance",
                field: "balance",
                formatter: "money",
                formatterParams: {precision: false, thousand: "."},
                bottomCalc: "sum",
                bottomCalcParams: {precision: false, thousand: "."}
            }
        ];
    }

    function submitTransactionIDForChecks() {
        Modal.confirm({
            title: 'Confirm Refund?',
            content: "Are you sure you want to refund these transactions ?",
            onOk() {
                setSubmitLoading(true);
                submitTransactionIds().then(data => {
                    // console.log(data);
                    let payload = JSON.parse(data.responseBody.Payload);
                    if (payload.success) {
                        const body = JSON.parse(payload.body);
                        setResultData(body);
                        setResultModalVisibility(true);
                    } else {
                        Modal.error({
                            title: 'Error',
                            content: payload.body,
                        });
                    }
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    setSubmitLoading(false);
                });
            }
        });
    }

    async function getTransactionsIds(transactionId: any) {

        let myInit = {
            headers: {
                'X-UID': getUidFromCookie()
            },
            queryStringParameters: {}
        };

        return API.get('pejuangweb', '/api/refundTransactionId/' + transactionId, myInit);
    }

    async function submitTransactionIds() {
        var id = await Auth.currentUserInfo();

        let myInit = {
            headers: {
                'X-UID': getUidFromCookie()
            },
            queryStringParameters: {
                transactionIds: JSON.stringify(listRefundBalance),
                userId: id.username
            }
        };

        return API.put('pejuangweb', '/api/refundTransaction/', myInit);
    }

    function DeleteButtonFormatter(props: any) {
        const cellData = props.cell._cell.row.data;
        return <Popconfirm title="Sure to delete?" onConfirm={() => setDeleteData(cellData)}>
            <Button danger style={{height: "25px"}}>Delete</Button>
        </Popconfirm>
    }

    const options = {
        height: 400,
    };

    function clearData() {
        setResultModalVisibility(false);
        window.location.reload();
    }


    return (
        <div>
            <Layout style={{ minHeight: '100vh' }}>
                <Content className="pejuang-main-content">
                    <Row>
                        <Col span={24}>
                            <Card bordered={false} className="header-background">
                                <Title level={4}>Refund Transactions</Title>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="content-layout">
                                <Row gutter={[20, 20]} style={{textAlign: "left"}}>
                                    <Col span={24}>
                                        <Card title="Input Form" bordered={false}>
                                            <Search
                                                placeholder="Transaction ID"
                                                enterButton="Add"
                                                loading={addLoading}
                                                onSearch={value => addRefundBalance(value)}
                                            />
                                            <Row>
                                                <ReactTabulator
                                                    columns={getModalColumns()}
                                                    data={listRefundBalance}
                                                    tooltips={true}
                                                    options={options}
                                                    layout={"fitData"}
                                                />
                                            </Row>
                                            <Row>
                                                <Button type="primary" loading={submitLoading}
                                                        onClick={() => submitTransactionIDForChecks()}>
                                                    Submit
                                                </Button>
                                            </Row>
                                            <Modal
                                                title="Result"
                                                centered
                                                width={1024}
                                                visible={resultModalVisibility}
                                                onOk={() => clearData()}
                                                onCancel={() => setResultModalVisibility(false)}
                                            >
                                                <ReactTabulator
                                                    columns={getResultModalColumns()}
                                                    data={resultData}
                                                    tooltips={true}
                                                    options={options}
                                                    layout={"fitData"}
                                                />
                                            </Modal>
                                        </Card>
                                    </Col>
                                </Row>
                        </Col>
                    </Row>
                </Content>
            </Layout>


        </div>
    );

};

export default RefundTransaction;
