import React, {useEffect, useState} from "react";
import {Typography, Col, Input, Row, Modal, Image, Select, Form, Button, Upload, DatePicker, InputNumber} from "antd";
import {API} from "@aws-amplify/api";
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import {ListUplineModal} from "../common/ListUplineModal";
import {getUidFromCookie} from "../../helpers/getUserId";

const { Option } = Select;
const { TextArea } = Input;
const moment = require('moment');

const { Text } = Typography;

type Props = {
	isVisible : any,
	id : any,
	onSaveComplete : any
}

export const VerifiedModal: React.FC<Props> = (props: any) => {
  const [loadingData, setLoadingData] = useState(true);
	const [isVisible, setIsVisible] = useState(props.isVisible);
	const [userData, setUserData] = useState<any | null>(null)
	const [allProducts, setAllProducts] = useState<Array<any>>([]);
	const [promoCode, promoCodeChange] = useState("")
	const [description, descriptionChange] = useState("")
	const [startDateForm, startDateFormChange] = useState(moment())
	const [endDateForm, endDateFormChange] = useState(moment())
	const [maxUsage, maxUsageChange] = useState(0)
	const [transactionType, transactionTypeChange] = useState("")
	const [productIds, productIdsChange] = useState<Array<any>>([]);
	const [minimalAmount, minimalAmountChange] = useState(0)
	const [amountType, amountTypeChange] = useState("")
	const [amountValue, amountValueChange] = useState(0)
	const [amountMaxValue, amountMaxValueChange] = useState(0)

	useEffect(() => {
		getCashbackDetail(props.id)
		// getProduct()
  }, [props.id])

  console.log("userData", userData)

	// useEffect(() => {
	// 	if (cashbackData !== null && props.id) {
	// 		promoCodeChange(cashbackData.id)
	// 		descriptionChange(cashbackData.description)
	// 		startDateFormChange(moment(cashbackData.dateStart))
	// 		endDateFormChange(moment(cashbackData.dateEnd))
	// 		maxUsageChange(cashbackData.maxUsage)
	// 		transactionTypeChange(cashbackData.transactionType)
	// 		productIdsChange(cashbackData.productIds)
	// 		minimalAmountChange(cashbackData.minimalAmount)
	// 		amountTypeChange(cashbackData.amount.type)
	// 		amountValueChange(cashbackData.amount.value)
	// 		amountMaxValueChange(cashbackData.amount.maxValue !== undefined || cashbackData.amount.maxValue !== null ? cashbackData.amount.maxValue : 0)
	// 	}
	// }, [cashbackData, props.id])

  function onModalCancelButton() {
		setIsVisible(false);
	}

	function getCashbackDetail(value: any) {
		setLoadingData(true)
		detailUserEndpoint(value).then(res => {
			const payload = JSON.parse(res.responseBody.Payload);
      if (payload.success) {
				const body = JSON.parse(payload.body);
				setUserData(body);
			}
		}).catch((err: any) => {
			setLoadingData(false)
			alert("Error : " + err)
		})
	}

	function getProduct() {
    queryAllProducts().then(res => {
      const payload = JSON.parse(res.responseBody.Payload)
      if (payload.success) {
        const products = JSON.parse(payload.body);

        let newProviders = {};
        for (let i in products) {
          var product = products[i];
          if (!newProviders.hasOwnProperty(product.provider)) {
            // @ts-ignore
            newProviders[product.provider] = [product];
          } else {
            // @ts-ignore
            newProviders[product.provider].push(product);
          }
        }

        let listProviders = Object.keys(newProviders);
				setAllProducts(listProviders)
			}
    })
	}

	function editUserRequest(type: any) {
		const data = {
			status: type
    }

		updateUserEndpoint(data).then(res => {
			var payload = JSON.parse(res.responseBody.Payload);
			if (payload.success) {
				setIsVisible(false);
				props.onSaveComplete();
			} else {
				//show error
			}
		})
	}

	async function detailUserEndpoint(params : any) {
		let init = {
			headers: {
				'X-UID': getUidFromCookie()
			}
		};
		return await API.get('pejuangweb', '/api/upgrade-request/' + params, init);
	}

	async function queryAllProducts() {
		return API.get('pejuangweb', '/api/product/', {
			headers: {
				'X-UID': getUidFromCookie()
			}
		});
  }

	async function updateUserEndpoint(params : any) {
		let init = {
			body: params,
			headers: {
				'X-UID': getUidFromCookie()
			}
		};
		return await API.put('pejuangweb', '/api/upgrade-request/' + props.id, init);
	}

	function renderFooter() {
		return [
			<>
				<Button onClick={() => onModalCancelButton()}>Cancel</Button>
				<Button type="primary" onClick={() => editUserRequest("accepted")}>Aprrove</Button>
				<Button type="primary" danger onClick={() => editUserRequest("rejected")}>Reject</Button>
			</>
		]
	}

  function renderContent() {
		// if (loadingData) {
		// 	return <div>
		// 		<LoadingOutlined style={{ fontSize: 24 }} spin />
		// 	</div>
		// }
		return (
			<div>
				{/* <Row>
					<Alert
						// className={hideErrorClassName}
						message="Error"
						description="Something wrong happened with the system. Please contact administrator."
						type="error"
						showIcon
					/>
				</Row> */}
				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col span={8} className="gutter-row">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12} className="gutter-row"><Text strong>ID Reseller</Text></Col>
              <Col span={12} className="gutter-row"><Text>: {userData && userData.userId}</Text></Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12} className="gutter-row"><Text strong>Reseller Name</Text></Col>
              <Col span={12} className="gutter-row"><Text>: {userData && userData.fullName}</Text></Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12} className="gutter-row"><Text strong>ID Card Number:</Text></Col>
              <Col span={12} className="gutter-row"><Text>: {userData && userData.noKTP}</Text></Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12} className="gutter-row"><Text strong>Request Date:</Text></Col>
              <Col span={12} className="gutter-row"><Text>: {userData && moment(userData.requestDate).format("DD-MMMM-YYYY")}</Text></Col>
            </Row>
					</Col>
					<Col span={8} className="gutter-row">
						<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24} className="gutter-row"><Text strong>Selfie Image</Text></Col>
							<Col span={24} className="gutter-row"><Image width={200} src={userData && userData.selfieImage} /></Col>
							<Col span={24} className="gutter-row"><Image width={200} src={userData && userData.selfie2Image }/></Col>
            </Row>
					</Col>
					<Col span={8} className="gutter-row">
						<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24} className="gutter-row"><Text strong>ID Card Image</Text></Col>
							<Col span={24} className="gutter-row"><Image width={200} src={userData && userData.KTPImage} /></Col>
            </Row>
					</Col>

        </Row>
			</div>
		)
	}

	return (
		<Modal
			width="10"
			style={{ marginLeft: '40px', marginRight: '40px' }}
			title="Preview Verified Reseller Request"
			visible={isVisible}
			onCancel={() => onModalCancelButton()}
			footer={renderFooter()}
		>
			{renderContent()}
		</Modal>
	)

}
