import {Auth} from "aws-amplify";
import Cookies from "universal-cookie";

export async function fetchUserId() {
  await Auth.currentSession().then((res:any) => { return res.accessToken.payload.sub}).catch ((err: any) => console.error("get userId error:", err));
}

const cookies = new Cookies();
export function getUidFromCookie() {
  return cookies.get("userId");
}
