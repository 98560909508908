import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import {
  Button,
  Card,
  Col,
  Layout,
  Popconfirm,
  Row,
  Menu,
  Typography,
  Dropdown,
  Modal,
} from "antd";
import { SyncOutlined, PlusCircleOutlined } from "@ant-design/icons";
import DataTable, { TableColumn } from "react-data-table-component";
import { tableStyle } from "../../helpers/StyleTable";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import { RoleModal } from "./ModalRole";
import { getUidFromCookie } from "../../helpers/getUserId";

const { Content } = Layout;
const { Title } = Typography;

type DataRow = {};
type Props = {};

const UserRole: React.FC<Props> = (props: any) => {
  const history = useHistory();
  const cookies = new Cookies();

  const [tableData, setTableData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [viewId, setViewId] = useState("");
  const [modalKey, setModalKey] = useState("");

  useEffect(() => {
    const params = { page: 1, perPage: 30 };
    getDataRole(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refreshData() {
    const params = { page: 1, perPage: 30 };
    getDataRole(params);
  }

  function getDataRole(params: any) {
    setLoadingData(true);
    queryGetRoleData(params).then((response) => {
      var payload = JSON.parse(response.responseBody.Payload);
      if (payload.success) {
        const body = JSON.parse(payload.body);
        setTableData(body);
      }
      setLoadingData(false);
    });
  }

  function deleteRole(value: any) {
    deleteRoleEndpoint(value)
      .then((res) => {
        Modal.success({
          content: "Success",
          onOk: (args: any) => {
            window.location.reload();
          },
        });
        setLoadingData(false);
      })
      .catch((err) => {
        alert("Error : " + err);
        setLoadingData(false);
      });
  }

  const handlePageChange = (page: any) => {
    const params = { page: page, perPage: 30 };
    getDataRole(params);
  };

  const handlePerRowsChange = async (data: any) => {
    const params = { perPage: 30 };
    queryGetRoleData(params)
      .then((response) => {
        var payload = JSON.parse(response.responseBody.Payload);
        if (payload.success) {
          const body = JSON.parse(payload.body);
          setTableData(body);
        }
        setLoadingData(false);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  };

  async function queryGetRoleData(params: any) {
    const myInit = {
      headers: { "X-UID": `${cookies.get("userId")}` },
      queryStringParameters: params,
    };
    return await API.get("pejuangweb", "/api/role/", myInit);
  }

  async function deleteRoleEndpoint(params: any) {
    let init = {
      headers: {
        "X-UID": getUidFromCookie(),
      },
    };
    return await API.del("pejuangweb", "/api/role/" + params, init);
  }

  const tableColumn: TableColumn<DataRow>[] = [
    {
      name: "ID",
      selector: (row: any) => row.id,
    },
    {
      name: "Name",
      selector: (row: any) => row.name,
    },
    {
      name: "Permissions",
      selector: (row: any) => renderPermission(row),
    },
    {
      name: "Actions",
      selector: (row: any) => renderActionButton(row),
    },
  ];

  function renderActionButton(record: any) {
    return (
      <div className="flex-column">
        <Button
          style={{ marginBottom: 10 }}
          onClick={() => handleViewButton(record)}
        >
          View
        </Button>
        <Popconfirm
          title="Sure to delete data?"
          onConfirm={() => deleteRole(record.id)}
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      </div>
    );
  }

  function renderPermission(record: any) {
    return (
      <Dropdown overlay={renderMenu(record.permissions)}>
        <Button>View Permission List</Button>
      </Dropdown>
    );
  }

  function renderMenu(items: any) {
    return (
      <Menu>
        {items && items.map((item: any) => <Menu.Item>{item}</Menu.Item>)}
      </Menu>
    );
  }

  function handleViewButton(record: any) {
    setModalKey((Math.random() * 1000).toString());
    setViewId(record.id);
    setViewDetailsModal(true);
  }

  function redirectToAddRole() {
    history.push("/create-role");
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content className="pejuang-main-content">
        <Row>
          <Col span={24}>
            <Card bordered={false} className="header-background">
              <Title level={4}>List User Role</Title>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="content-layout">
            <Card>
              <Row>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    style={{ marginRight: "20px" }}
                    onClick={() => redirectToAddRole()}
                  >
                    Add Role
                  </Button>
                  <Button
                    type="default"
                    onClick={() => refreshData()}
                    icon={<SyncOutlined />}
                  >
                    Refresh
                  </Button>
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={24}>
                  <DataTable
                    columns={tableColumn}
                    data={tableData}
                    progressPending={loadingData}
                    pagination
                    customStyles={tableStyle}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationServer
                    onChangePage={handlePageChange}
                  />
                </Col>
              </Row>

              <br />
            </Card>
          </Col>
        </Row>
        <RoleModal
          isVisible={viewDetailsModal}
          id={viewId}
          key={modalKey}
          onSaveComplete={() => refreshData()}
        />
      </Content>
    </Layout>
  );
};

export default UserRole;
