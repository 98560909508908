export const tableStyle = {
  table: {
    style: {
      borderTop: '1px solid rgba(0,0,0,.12)',
      borderLeft: '1px solid rgba(0,0,0,.12)',
      marginTop: 20,
    }
  },
  headCells: {
    style: {
      fontSize: '14px',
      fontWeight: 'bold',
      borderRight: '1px solid rgba(0,0,0,.12)',
      backgroundColor: '#f7f7f7',
		},
  },
  cells: {
		style: {
      fontSize: '14px',
      borderRight: '1px solid rgba(0,0,0,.12)',
      paddingTop: "5px",
      paddingBottom: "5px",
		},
  },
}