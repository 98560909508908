
import React, {useEffect, useState} from "react";
import { API } from "aws-amplify";
import {Button, Card, Col, Input, Layout, Popconfirm, Row, Tag, Typography, DatePicker, Modal} from "antd";
import {
	ExclamationCircleTwoTone,
	CheckCircleTwoTone,
	CrownFilled,
	SyncOutlined,
	LoadingOutlined
} from '@ant-design/icons';
import DataTable, { TableColumn } from 'react-data-table-component';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

import { ResellerDetailsModal } from "./ResellerDetailsModal";
import { tableStyle } from "./tableStyle";
import { toCurrency } from "../../helpers/ToCurrency";
import {getUidFromCookie} from "../../helpers/getUserId";

var moment = require('moment');
const { Content } = Layout;
const { Title } = Typography;
const { Search } = Input;

type DataRow = {
	title: any;
	director: any;
	year: any;
};

type QueryParams = {
	q: string,

};


const Resellers: React.FC = () => {

	const [downloadLoading, setDownloadLoading] = useState(false);
	const [queryLoading, setQueryLoading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [totalData, setTotalData] = useState(0);
	const [loadingData, setLoadingData] = useState(false);
	const [viewDetailsModal, setViewDetailsModal] = useState(false);
	const [viewId, setViewId] = useState('');
	const [modalKey, setModalKey] = useState('');
	const [endBalanceDate, setEndBalanceDate] = useState(moment());
	const [searchValue, setSearchValue] = useState("")

	useEffect(() => {
		const params = { page: 1, perPage: 10}
		getDataUser(params);
	}, []);

	function refreshData() {
		const params = { page: 1, perPage: 10}
		getDataUser(params);
		setSearchValue("");
		setEndBalanceDate(moment())
	}

	function getDataUser(params: any) {
		setLoadingData(true);
		query(params).then(response => {
			var payload = JSON.parse(response.responseBody.Payload);
			if (payload.success) {
				const body = JSON.parse(payload.body);
				setTableData(body.data);
				setTotalData(body.total);
			}
			setLoadingData(false);
		});
		setQueryLoading(false);
	}

	function searchData(value: any) {
		setLoadingData(true);
		setSearchValue(value)
		const params = {q: value, page: 1, perPage: 10, balanceUpdateDateMax: endBalanceDate.endOf('day').valueOf()}
		query(params).then(response => {
			var payload = JSON.parse(response.responseBody.Payload);
			if (payload.success) {
				const body = JSON.parse(payload.body);
				setTableData(body.data);
				setTotalData(body.total);
			}
			setLoadingData(false);
		}).catch(err => {
			setLoadingData(false);
		});
	}

	const handlePageChange = (page: any) => {
		const params = { page: page, perPage: 10, search: searchValue, balanceUpdateDateMax: endBalanceDate.endOf('day').valueOf()}
		getDataUser(params);
	};


	const handlePerRowsChange = async (data: any) => {
		const params = { q: searchValue, page: data.page, perPage: 10, balanceUpdateDateMax: endBalanceDate.endOf('day').valueOf()}
		query(params).then(response => {
			var payload = JSON.parse(response.responseBody.Payload);
			if (payload.success) {
				const body = JSON.parse(payload.body);
				setTableData(body.data);
				setTotalData(body.total);
			}
			setLoadingData(false);
		}).catch(err => {
			setLoadingData(false);
		});
	};

	function getEndbalance() {
		setLoadingData(true);
		const params = { page: 1, perPage: 10, balanceUpdateDateMax: endBalanceDate.endOf('day').valueOf()}
		query(params).then(response => {
			var payload = JSON.parse(response.responseBody.Payload);
			if (payload.success) {
				const body = JSON.parse(payload.body);
				setTableData(body.data);
				setTotalData(body.total);
			}
			setLoadingData(false);
		}).catch(err => {
			setLoadingData(false);
		});
	}

	async function query(params : any) {
		const myInit =  {
			queryStringParameters: params,
			headers: {
				'X-UID': getUidFromCookie()
			}
		}
		return await API.get('pejuangweb', '/api/user/', myInit);
	}

	const tableColumn: TableColumn<DataRow>[] = [
    {
			name: 'Reseller ID',
			selector: (row: any) => row.id,
			minWidth: "150px",
    },
    {
      name: 'Reseller Name',
			selector: (row: any) => ( <p> {row.name} {row.isPremium &&<CrownFilled style={{color:"#FFD700", fontSize:"18px"}}/>}</p>),
			sortable: true,
			minWidth: "250px",
    },
    {
			name: 'Phone Number',
			selector: (row: any) => row.phoneNumber,
			minWidth: "150px",
		},
		{
			name: 'Telegram ID',
			selector: (row: any) => row.telegramId,
			minWidth: "150px",
		},
		{
			name: 'Upline ID',
			selector: (row: any) => row.uplineId,
			minWidth: "150px",
		},
		{
			name: 'Upline Name',
			selector: (row: any) => row.uplineName,
			minWidth: "150px",
			sortable: true,
		},
		{
			name: 'Reseller End Balance',
			selector: (row: any) => toCurrency(row.endBalance),
			minWidth: "200px",
			sortable: true,
		},
		{
			name: 'Active',
			minWidth: "60px",
			selector: (row: any) => (
				<span>
					<Tag color={getTagColor(row)}>
						{getTagMessage(row)}
					</Tag>
				</span>
			),
		},
		{
			name: 'Actions',
			minWidth: "200px",
			selector: (row: any) => renderActionButton(row)
		},
	];

	function toggleActivation(record : any){
		let params = {
			isActive : !record.isActive
		};
		updateUserActivation(record.id, params).then(data => {
			Modal.success({
        content: !record.isActive ? 'Success Active Reseller' : 'Success Deactivate Reseller' ,
        onOk : (args: any) => {
          window.location.reload();
        }
      });
		}).catch(err => {
		}).finally(() => {
			refreshData();
		});
	}

	function renderActivateOrDeactivate(record : any){
		if (record.isActive) {
			return <Popconfirm title="Sure to deactivate?" onConfirm={() => toggleActivation(record)}>
				<Button danger ghost style={{marginBottom: 7}} >Deactivate</Button>
			</Popconfirm>
		} else {
			return <Popconfirm title="Sure to activate?" onConfirm={() => toggleActivation(record)}>
				<Button type="primary" ghost style={{ marginBottom: 7 }}>Activate</Button>
			</Popconfirm>
		}
	}

	function renderResetButton(record : any){
		return <Popconfirm title="Sure to reset pin?" onConfirm={() => resetPin(record.id)}>
			<Button type="primary">Reset PIN</Button>
		</Popconfirm>
	}

	function renderActionButton(record : any){
		return (
			<div className="flex-column">
				<Button style={{ marginBottom: 7 }} onClick={() => handleViewButton(record)}>View</Button>
				{renderActivateOrDeactivate(record)}
				{renderResetButton(record)}
			</div>
		)
	}

	function resetPin(data: any){
		queryResetPin(data).then(res => {
			Modal.success({
        content: 'Success Reset Pin',
        onOk : (args: any) => {
          window.location.reload();
        }
      });
		}).catch(err => console.error("Error Reset:", err))
	}

	async function queryResetPin(params: any) {
		const myInit = {
			headers: {
				'X-UID': getUidFromCookie()
			}
		}
		return await API.del('pejuangweb', `/api/user-pin/${params}`, myInit);
	}

	function handleViewButton(record : any) {
		setModalKey((Math.random() * 1000).toString());
		setViewId(record.id);
		setViewDetailsModal(true);
	}

	async function updateUserActivation(id : any, params : any) {
		var init = {
			body: params,
			headers: {
				'X-UID': getUidFromCookie()
			}
		};
		return API.put('pejuangweb', '/api/userActivation/' + id, init);
	}

	function getTagColor(record: any){
		if (record.isActive) {
			return 'green';
		} else {
			return 'red';
		}
	}

	function getTagMessage(record: any){
		if (record.isActive) {
			return <CheckCircleTwoTone twoToneColor="#52c41a"/>;
		} else {
			return <ExclamationCircleTwoTone twoToneColor="#eb2f96"/>;
		}
	}

	async function downloadExcel() {
		setDownloadLoading(true);
		const params = { all: true, balanceUpdateDateMax: endBalanceDate.endOf('day').valueOf()}
		query(params).then(response => {
			var payload = JSON.parse(response.responseBody.Payload);

			if (payload.success) {

				const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
				const fileExtension = '.xlsx';
				const body = JSON.parse(payload.body);
				//@ts-ignore
				const ws = XLSX.utils.json_to_sheet(body.data);
				const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
				const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
				const newData = new Blob([excelBuffer], {type: fileType});
				const newDate = new Date();
				FileSaver.saveAs(newData, "RESELLER_" + newDate + fileExtension);
			}
			setDownloadLoading(false);
		}).catch(err => {
			console.error("error download", err)
			setDownloadLoading(false);
		});
	}

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Content className="pejuang-main-content">
				<Row>
					<Col span={24}>
						<Card bordered={false} className="header-background">
							<Title level={4}>List Resellers</Title>
						</Card>
					</Col>
				</Row>


				<Row>
					<Col span={24} className="content-layout">
						<Card>
							<Row>
								<Col span={18} style={{ textAlign: "left" }}>
									<DatePicker placeholder="Date End Balance" style={{width: "200px"}} value={endBalanceDate} onChange={(dates, dateString) => setEndBalanceDate(dates)} />
									<Button type="primary" onClick={() => getEndbalance()} style={{marginLeft: 20}}>Query</Button>
								</Col>
								<Col span={6} style={{ textAlign: "right" }}>
									<Button type="primary" onClick={() => downloadExcel()} style={{ marginRight: 20 }} icon={downloadLoading && <LoadingOutlined />} disabled={downloadLoading}>Download
										Excel</Button>
									<Button type="default" onClick={() => refreshData()}  icon={<SyncOutlined />}>
										Refresh
									</Button>
								</Col>
							</Row>
							<br/>
							<Row>
								<Col span={24}>
									<Search
										allowClear
										placeholder="Input Reseller Name or Phone to search.."
										enterButton="Search"
										loading={loadingData}
										onSearch={value => searchData(value)}>
										</Search>
								</Col>
								<Col span={24}>
									<DataTable
										columns={tableColumn}
										data={tableData}
										progressPending={loadingData}
										pagination
										customStyles={tableStyle}
										onChangeRowsPerPage={handlePerRowsChange}
										paginationServer
										paginationTotalRows={totalData}
										onChangePage={handlePageChange}
									/>
								</Col>
							</Row>

							<br/>
						</Card>
					</Col>
				</Row>
				<ResellerDetailsModal isVisible={viewDetailsModal}
				                      id={viewId} key={modalKey} onSaveComplete={() => refreshData()}/>
			</Content>
		</Layout>
	);
};

export default Resellers
