/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Col, Input, Row, Modal, Alert, Table } from "antd";
import { API } from "@aws-amplify/api";
import { getUidFromCookie } from "../../helpers/getUserId";
const { Search } = Input;

type Props = {
  isVisible: any;
  onSelectData: any;
};

export const ListUplineModal: React.FC<Props> = (props) => {
  const [loadingData, setLoadingData] = useState(false);
  const [isVisible, setIsVisible] = useState(props.isVisible);
  const [hideErrorClassName, setHideErrorClassName] = useState("hide");
  const [tableData, setTableData] = useState([]);
  const [selectedData, setSelectedData] = useState({});

  function onModalCancelButton() {
    setIsVisible(false);
  }

  useEffect(() => {}, []);

  function onModalSaveButton() {
    props.onSelectData(selectedData);
    setIsVisible(false);
  }

  async function search(params: any) {
    let init = {
      headers: {
        "X-UID": getUidFromCookie(),
      },
    };
    return await API.get("pejuangweb", "/api/username/" + params, init);
  }

  const modalColumns = [
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a: { id: string }, b: { id: string }) =>
        a.id.localeCompare(b.id),
      sortDirections: ["descend" as "descend", "ascend" as "ascend"],
      defaultSortOrder: "descend",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      sorter: (a: { phoneNumber: string }, b: { phoneNumber: string }) =>
        a.phoneNumber.localeCompare(b.phoneNumber),
      sortDirections: ["descend" as "descend", "ascend" as "ascend"],
      defaultSortOrder: "descend",
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a: { name: string }, b: { name: string }) =>
        a.name.localeCompare(b.name),
      sortDirections: ["descend" as "descend", "ascend" as "ascend"],
    },
  ];

  function searchData(value: any) {
    setLoadingData(true);
    search(value)
      .then((response) => {
        var payload = JSON.parse(response.responseBody.Payload);
        if (payload.success) {
          const body = JSON.parse(payload.body);
          let newDatas: any = [];
          for (let i in body) {
            let newData = JSON.parse(JSON.stringify(body[i]));
            newData["key"] = newData.id;
            newDatas.push(newData);
          }
          setTableData(newDatas);
        }
        setLoadingData(false);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  }

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(selectedRows);
      setSelectedData(selectedRows[0]);
    },
    getCheckboxProps: (record: any) => ({
      disabled: !record.isActive, // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <Modal
      width="10"
      style={{ marginLeft: "40px", marginRight: "40px" }}
      title="Select Upline"
      visible={isVisible}
      onOk={() => onModalSaveButton()}
      okText="Select"
      onCancel={() => onModalCancelButton()}
    >
      <div>
        <Row>
          <Alert
            className={hideErrorClassName}
            message="Error"
            description="Something wrong happened with the system. Please contact administrator."
            type="error"
            showIcon
          />
        </Row>
        <Row>
          <Col span={24}>
            <Search
              placeholder="Input upline name to search.."
              enterButton="Search"
              loading={loadingData}
              onSearch={(value) => searchData(value)}
            />
          </Col>
        </Row>
        <br />

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24} className="gutter-row">
            <Table
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              size={"small"}
              loading={loadingData}
              bordered
              scroll={{ x: true }}
              dataSource={tableData}
              // @ts-ignore
              columns={modalColumns}
            />
          </Col>
        </Row>
        <br />
        <br />
      </div>
    </Modal>
  );
};
