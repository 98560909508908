import React, {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Card,
    Col, Divider,
    Form,
    Layout,
    Modal,
    PageHeader, Popconfirm,
    Result,
    Row,
    Switch,
    Table,
    Tag,
    Typography
} from "antd";
import {API} from "aws-amplify";
import {ResellerDetailsModal} from "../reseller/ResellerDetailsModal";
import {ListUplineModal} from "../common/ListUplineModal";
import {getUidFromCookie} from "../../helpers/getUserId";

const {confirm} = Modal;
const { Content, Footer } = Layout;
const { Title } = Typography;

type TerminalInboxProps = {}

const Maintenance: React.FC<TerminalInboxProps> = (props) => {
    const [loadingData, setLoadingData] = useState(false);
    const [terminalState, setTerminalState] = useState({});
    const [queryLoading, setQueryLoading] = useState(false);
    const [userData, setUserData] = useState([]);
    const [uplineModalKey, setUplineModalKey] = useState('');
    const [showUplineModal, setShowUplineModal] = useState(false);


    useEffect(() => {
        doQueryData();
    }, []);


    function doQueryData() {
        setLoadingData(true);
        getTerminalConfig().then(response => {
            parseResponse(response, false);
        }).catch(err => {
            setLoadingData(false);
        })
    }

    function parseResponse(response: any, appendData: boolean) {
        var payload = JSON.parse(response.responseBody.Payload);
        if (payload.success) {
            var body = JSON.parse(payload.body);
            setTerminalState(body);
            setUserData(body.whitelistUsers);
        }
        setLoadingData(false);
    }

    function getParams() {
        return {
            headers: {
                'X-UID': getUidFromCookie()
            },
            queryStringParameters: {}
        };
    }

    function getTerminalConfig() {
        return API.get('pejuangweb', '/api/maintenanceConfig', getParams());
    }

    function updateConfig(params: any) {
        var init = {
            body: params,
            headers: {
                'X-UID': getUidFromCookie()
            }
        };
        return API.put('pejuangweb', '/api/maintenanceConfig/1', init);
    }

    function onChangeTerminalState(switchValue: any) {
        let oldTerminalState = JSON.parse(JSON.stringify(terminalState));
        oldTerminalState.value = switchValue;
        setTerminalState(oldTerminalState);
    }

    function getUsersId() {
        let ids: any = [];
        for (let i in userData) {
            ids.push(userData[i]["id"]);
        }
        return ids;
    }

    function onUpdateConfig() {
        setQueryLoading(true)
        let newTerminalState = {
            // @ts-ignore
            value : terminalState["value"],
            whitelistUsers : getUsersId()
        };
        updateConfig(newTerminalState).then(response => {
            var payload = JSON.parse(response.responseBody.Payload);
            if (payload.success) {
                Modal.success({
                    content: 'Success updating the config',
                    onOk : args => {
                        window.location.reload();
                    }
                });
            } else {
                Modal.error({
                    content: 'Failed updating the config',
                    onOk : args => {
                        window.location.reload();
                    }
                });
            }
        }).catch(err => {
            setQueryLoading(false);
            Modal.error({
                content: 'Failed updating the config',
                onOk : args => {
                    window.location.reload();
                }
            });
        });
    }

    const modalColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: (a: { id: string; }, b: { id: string; }) => a.id.localeCompare(b.id),
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],

        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            sorter: (a: { phoneNumber: string; }, b: { phoneNumber: string; }) => a.phoneNumber.localeCompare(b.phoneNumber),
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            defaultSortOrder: 'descend',

        },
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: (a: { name: string; }, b: { name: string; }) => a.name.localeCompare(b.name),
            sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
            defaultSortOrder: 'descend',

        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                renderActionButton(record)
            ),
        },
    ];

    function handleDeleteData(record: any) {
        let newUserData: any = [];
        for (let i in userData) {
            if (userData[i]["id"] !== record.id) {
                newUserData.push(userData[i])
            }
        }
        setUserData(newUserData);
    }

    function renderActionButton(record : any){
        return <span>
                <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteData(record)}>
                    <a>Delete</a>
                </Popconfirm>
            </span>
    }


    function showConfirm() {
        let message = "";
        // @ts-ignore
        if (terminalState["value"]) {
            message = "Are you sure you want to TURN ON the MAINTENANCE mode?";
        } else {
            message = "Are you sure you want to TURN OFF the MAINTENANCE mode?";
        }

        confirm({
            title: message,
            content: '',
            onOk() {
                onUpdateConfig();
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    function onSelectUpline(uplineData : any) {
        let newUserData = JSON.parse(JSON.stringify(userData));
        newUserData.push(uplineData);
        setUserData(newUserData);
    }


    function onShowUplineModal() {
        setShowUplineModal(true);
        setUplineModalKey((Math.random() * 1000).toString());
    }


    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content className="pejuang-main-content">
                <Row>
                    <Col span={24}>
                        <Card bordered={false} className="header-background">
                            <Title level={4}>Maintenance Config</Title>
                        </Card>
                    </Col>
                </Row>


                <Row>
                    <Col span={24} className="content-layout">
                        <Card>
                            <Row style={{textAlign: "left"}}>
                                <Col span={24}>
                                        <Row>
                                            <Alert
                                                message="MAINTENANCE Mode"
                                                description="Turning on the maintenance mode might cause the transactions to failed."
                                                type="warning"
                                                showIcon
                                            />
                                        </Row>
                                        <Row gutter={[40, 40]}>
                                            <Col span={24} style={{textAlign: "left", marginTop: 20}}>
                                                <Row>
                                                    <Switch onChange={(value: any) => onChangeTerminalState(value)}
                                                            checkedChildren="MAINTENANCE mode ON" unCheckedChildren="MAINTENANCE mode OFF"
                                                        // @ts-ignore
                                                            checked={terminalState["value"]} loading={loadingData}/>
                                                </Row>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} style={{textAlign: "left", marginTop: 20, marginBottom: 20}}>
                                                <Title level={5}>Whitelist Users</Title>
                                                <Button type="primary" onClick={() => onShowUplineModal()}
                                                        style={{float: "right", marginRight : "10px", marginBottom : "20px"}}>Add User</Button>
                                                <Table
                                                size="small"
                                                loading={loadingData}
                                                bordered
                                                scroll={{x: true}}
                                                dataSource={userData}
                                                // @ts-ignore
                                                columns={modalColumns}
                                            />
                                            </Col>
                                        </Row>
                                        <Row gutter={[40, 40]}>
                                            <Col span={24} style={{textAlign: "left"}}>
                                                <Button type="primary" style={{marginLeft: "10px"}} onClick={() => showConfirm()}
                                                        loading={queryLoading}>Save Config</Button>
                                            </Col>
                                        </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <ListUplineModal
                    key={uplineModalKey}
                    isVisible={showUplineModal}
                    onSelectData={(uplineData : any) => onSelectUpline(uplineData)}/>
            </Content>
        </Layout>
    );
};


export default Maintenance;
