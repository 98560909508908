/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  Row,
  Table,
  Typography,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { API } from "aws-amplify";
import Highlighter from "react-highlight-words";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { getUidFromCookie } from "../../helpers/getUserId";

const { Title } = Typography;
const { RangePicker } = DatePicker;

var moment = require("moment");
var searchInput: any;

type TransferBankProps = {};

const Disbursement: React.FC<TransferBankProps> = (TransferBankProps) => {
  const [loadingData, setLoadingData] = useState(false);
  const [inboxData, setInboxData] = useState([]);
  const [filterStartTimestamp, onFilterStartTimestampChange] = useState(
    moment()
  );
  const [filterEndTimestamp, onFilterEndTimestampChange] = useState(moment());
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchColumn] = useState("");
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [moreDataLoading, setMoreDataLoading] = useState(false);
  const [queryLoading, setQueryLoading] = useState(false);

  function handleSearch(selectedKeys: any, confirm: any, dataIndex: any) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  }

  function handleReset(clearFilters: any) {
    clearFilters();
    setSearchText("");
  }

  const getColoumnSearch = (dataIndex: any) => ({
    // @ts-ignore
    filterDropdown: ({
      // @ts-ignore
      setSelectedKeys,
      // @ts-ignore
      selectedKeys,
      // @ts-ignore
      confirm,
      // @ts-ignore
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined />,
    onFilter: (value: any, record: any) => {
      if (record[dataIndex] === undefined) {
        return false;
      }
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      sorter: (a: { id: string }, b: { id: string }) =>
        a.id.localeCompare(b.id),
      sortDirections: ["descend" as "descend", "ascend" as "ascend"],
      ...getColoumnSearch("id"),
    },
    {
      title: "User ID",
      dataIndex: "userId",
      sorter: (a: { id: string }, b: { id: string }) =>
        a.id.localeCompare(b.id),
      sortDirections: ["descend" as "descend", "ascend" as "ascend"],
      ...getColoumnSearch("userId"),
    },
    {
      title: "Username",
      dataIndex: "name",
      sorter: (a: { id: string }, b: { id: string }) =>
        a.id.localeCompare(b.id),
      sortDirections: ["descend" as "descend", "ascend" as "ascend"],
      ...getColoumnSearch("name"),
    },
    {
      title: "Timestamp",
      dataIndex: "requestTimestampString",
      sorter: (
        a: { requestTimestamp: number },
        b: { requestTimestamp: number }
      ) => a.requestTimestamp - b.requestTimestamp,
      sortDirections: ["descend" as "descend", "ascend" as "ascend"],
      defaultSortOrder: "descend",
      ...getColoumnSearch("requestTimestampString"),
    },
    {
      title: "Update Timestamp",
      dataIndex: "updatedTimestamp",
      sorter: (
        a: { updatedTimestamp: string },
        b: { updatedTimestamp: string }
      ) => a.updatedTimestamp.localeCompare(b.updatedTimestamp),
      sortDirections: ["descend" as "descend", "ascend" as "ascend"],
      defaultSortOrder: "descend",
      ...getColoumnSearch("updatedTimestamp"),
      render: (text: any, record: any) => (
        <Typography.Text>
          {moment.utc(text).local().format("DD/MM/YY HH:mm:ss.SSSZ")}
        </Typography.Text>
      ),
    },
    {
      title: "Nominal",
      dataIndex: "balance",
      sorter: (a: { balance: number }, b: { balance: number }) =>
        a.balance - b.balance,
      sortDirections: ["descend" as "descend", "ascend" as "ascend"],
      ...getColoumnSearch("balance"),
      render: (text: any, record: any) => (
        <Typography.Text>{toCurrency(text)}</Typography.Text>
      ),
    },
    {
      title: "Bank",
      dataIndex: "bankCode",
      sorter: (a: { id: string }, b: { id: string }) =>
        a.id.localeCompare(b.id),
      sortDirections: ["descend" as "descend", "ascend" as "ascend"],
      ...getColoumnSearch("bankCode"),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a: { status: string }, b: { status: string }) =>
        a.status.localeCompare(b.status),
      sortDirections: ["descend" as "descend", "ascend" as "ascend"],
      ...getColoumnSearch("status"),
    },
    {
      title: "Note",
      dataIndex: "notes",
      sorter: (a: { notes: string }, b: { notes: string }) =>
        a.notes.localeCompare(b.notes),
      sortDirections: ["descend" as "descend", "ascend" as "ascend"],
      ...getColoumnSearch("notes"),
    },
  ];

  function getDataParams() {
    let params = {
      headers: {
        "X-UID": getUidFromCookie(),
      },
      queryStringParameters: {},
    };

    // @ts-ignore
    params["queryStringParameters"]["endTimestamp"] = filterEndTimestamp
      .endOf("day")
      .valueOf();
    // @ts-ignore
    params["queryStringParameters"]["startTimestamp"] = filterStartTimestamp
      .startOf("day")
      .valueOf();

    return params;
  }

  function handleDateChange(dates: any) {
    onFilterStartTimestampChange(dates[0]);
    onFilterEndTimestampChange(dates[1]);
  }

  function parseItems(items: any) {
    var newItems = [];
    for (var i in items) {
      var item = items[i];
      item["requestTimestampString"] = moment
        .utc(item["requestTimestamp"])
        .local()
        .format("DD/MM/YY HH:mm:ss.SSSZ");

      if (!item.hasOwnProperty("user")) {
        item["user"] = "";
      }
      newItems.push(item);
    }

    return newItems;
  }

  function parseResponse(response: any, appendData: boolean) {
    setLoadingData(false);
    var paylaod = JSON.parse(response.responseBody.Payload);
    var body = JSON.parse(paylaod.body);
    var items = body.items;
    var newItems = parseItems(items);

    if (body.hasOwnProperty("LastEvaluatedKey")) {
      setLastEvaluatedKey(body.LastEvaluatedKey);
    } else {
      setLastEvaluatedKey("");
    }
    if (appendData) {
      //@ts-ignore
      setInboxData(inboxData.concat(newItems));
    } else {
      //@ts-ignore
      setInboxData(newItems);
    }
  }

  function toCurrency(numberString: any) {
    let number = parseFloat(numberString);
    return number.toLocaleString("ID");
  }

  function getData() {
    let itemsData = API.get(
      "pejuangweb",
      "/api/paymentPayoutHistory",
      getDataParams()
    );
    return itemsData;
  }

  function getDataMore() {
    let params = getDataParams();
    if (lastEvaluatedKey !== "") {
      //@ts-ignore
      params.queryStringParameters["lastEvaluatedKey"] =
        JSON.stringify(lastEvaluatedKey);
    }
    let itemsData = API.get("pejuangweb", "/api/paymentPayoutHistory", params);
    return itemsData;
  }

  function doQuery() {
    setLoadingData(true);
    getData()
      .then((response) => {
        parseResponse(response, false);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  }

  function handleMoreQuery() {
    setMoreDataLoading(true);
    getDataMore()
      .then((response) => {
        parseResponse(response, true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setMoreDataLoading(false);
      });
  }

  function downloadExcel() {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(inboxData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const date = filterStartTimestamp + "_" + filterEndTimestamp;
    FileSaver.saveAs(data, "MutationBalance_" + date + fileExtension);
  }

  return (
    <div>
      <Row style={{ textAlign: "left" }}>
        <Col span={24}>
          <Card bordered={false} className="header-background">
            <Title level={4}>Bank Transfer</Title>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="content-layout">
          <Card>
            <br />
            <Row>
              <Col span={24} style={{ textAlign: "left" }}>
                <RangePicker
                  size="large"
                  onChange={(dates, dateString) => handleDateChange(dates)}
                  defaultValue={[moment(), moment()]}
                />

                <Button
                  type="primary"
                  style={{ marginLeft: "10px" }}
                  onClick={() => doQuery()}
                >
                  Query Data
                </Button>
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={24}>
                {inboxData.length !== 0 && (
                  <Button
                    type="default"
                    style={{ marginTop: "10px", float: "right", zIndex: 9999 }}
                    onClick={() => downloadExcel()}
                    loading={queryLoading}
                  >
                    Download Data
                  </Button>
                )}
                {lastEvaluatedKey !== "" && (
                  <Button
                    type="primary"
                    onClick={() => handleMoreQuery()}
                    loading={moreDataLoading}
                    style={{
                      float: "right",
                      marginTop: "10px",
                      zIndex: 9999,
                      marginRight: "10px",
                    }}
                  >
                    View More
                  </Button>
                )}
                <Table
                  size="small"
                  loading={loadingData}
                  bordered
                  scroll={{ x: true }}
                  dataSource={inboxData}
                  // @ts-ignore
                  columns={columns}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Disbursement;
