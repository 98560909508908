import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import {
  Button,
  Card,
  Col,
  Divider,
  Layout,
  Popconfirm,
  Row,
  Table,
  Tabs,
  Tag,
  Typography,
} from "antd";

import ManualDeposit from "./ManualDeposit";
import TopupChannel from "./TopupChannel";
import TransferSaldo from "./TransferSaldo";

const { Content, Footer } = Layout;
const { Title } = Typography;
const { TabPane } = Tabs;

const TopupDeposit: React.FC = () => {
  useEffect(() => {}, []);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content className="pejuang-main-content">
        <Row>
          <Col span={24}>
            <Card bordered={false} className="header-background">
              <Title level={4}>Topup Deposit</Title>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="content-layout">
            <Card>
              <Tabs defaultActiveKey="2">
                {/* Hide until next confirmation */}
                {/* <TabPane tab="Manual Deposit" key="1">
									<ManualDeposit/>
								</TabPane> */}
                <TabPane tab="Topup Channel" key="2">
                  <TopupChannel />
                </TabPane>
                <TabPane tab="Transfer Saldo" key="3">
                  <TransferSaldo />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default TopupDeposit;
