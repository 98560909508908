import React, { useEffect, useState } from "react";
import { Col, Input, Row, Modal, Select } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { API } from "@aws-amplify/api";
import Cookies from "universal-cookie";
import { getUidFromCookie } from "../../helpers/getUserId";

const { Option } = Select;
type Props = {
  isVisible: any;
  id: any;
  onSaveComplete: any;
};

export const UserModal: React.FC<Props> = (props: any) => {
  const cookies = new Cookies();
  const [loadingData, setLoadingData] = useState(true);
  const [isVisible, setIsVisible] = useState(props.isVisible);
  const [userData, setUserData] = useState<any | null>(null);
  const [allRole, setAllRole] = useState<Array<any>>([]);
  const [username, usernameChange] = useState("");
  const [email, emailChange] = useState("");
  const [role, roleChange] = useState("");

  useEffect(() => {
    getUserDetail(props.id);
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  useEffect(() => {
    if (userData !== null && props.id) {
      usernameChange(userData.username);
      emailChange(userData.email);
      roleChange(userData.role);
    }
  }, [userData, props.id]);

  function onModalCancelButton() {
    setIsVisible(false);
  }

  function getUserDetail(value: any) {
    setLoadingData(true);
    detailUserEndpoint(value)
      .then((res) => {
        const payload = JSON.parse(res.responseBody.Payload);
        if (payload.success) {
          const body = JSON.parse(payload.body);
          setUserData(body);
        }
        setLoadingData(false);
      })
      .catch((err: any) => {
        setLoadingData(false);
        alert("Error : " + err);
      });
  }

  function getRoles() {
    queryAllRole().then((response) => {
      const payload = JSON.parse(response.responseBody.Payload);
      if (payload.success) {
        const body = JSON.parse(payload.body);
        setAllRole(body);
      }
    });
  }

  function editRoleRequest() {
    const data = {
      roleId: role,
    };

    updateRoleEndpoint(data).then((res) => {
      var payload = JSON.parse(res.responseBody.Payload);
      if (payload.success) {
        setIsVisible(false);
        props.onSaveComplete();
      } else {
        //show error
      }
    });
  }

  async function detailUserEndpoint(params: any) {
    let init = {
      headers: {
        "X-UID": getUidFromCookie(),
      },
    };
    return await API.get("pejuangweb", "/api/admin-user/" + params, init);
  }

  async function queryAllRole() {
    const myInit = {
      headers: { "X-UID": `${cookies.get("userId")}` },
      queryStringParameters: { page: 1, perPage: 50 },
    };
    return API.get("pejuangweb", "/api/role/", myInit);
  }

  async function updateRoleEndpoint(params: any) {
    let init = {
      body: params,
      headers: {
        "X-UID": getUidFromCookie(),
      },
    };
    return await API.put("pejuangweb", "/api/admin-user/" + props.id, init);
  }

  function renderContent() {
    if (loadingData) {
      return (
        <div>
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    }
    return (
      <div>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8} className="gutter-row">
            Username:
          </Col>
          <Col span={8} className="gutter-row">
            Email:
          </Col>
          <Col span={8} className="gutter-row">
            Role:
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8} className="gutter-row">
            <Input
              value={username}
              onChange={(event: any) => usernameChange(event.target.value)}
              disabled
            />
          </Col>
          <Col span={8} className="gutter-row">
            <Input
              value={email}
              onChange={(event: any) => emailChange(event.target.value)}
              disabled
            />
          </Col>
          <Col span={8} className="gutter-row">
            <Select
              allowClear
              style={{ width: "100%" }}
              value={role}
              onChange={(value: any) => roleChange(value)}
              placeholder="Select new role"
            >
              {allRole.map((data: any, index: any) => {
                return (
                  <Option value={data.id} key={data.id} x>
                    {data.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <Modal
      width="10"
      style={{ marginLeft: "40px", marginRight: "40px" }}
      title="User Admin Detail"
      visible={isVisible}
      okText="Update Role"
      onCancel={() => onModalCancelButton()}
      onOk={() => editRoleRequest()}
    >
      {renderContent()}
    </Modal>
  );
};
