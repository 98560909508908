import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { Button, Card, Col, Input, Layout, Row, Typography } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import DataTable, { TableColumn } from "react-data-table-component";

import { VerifiedModal } from "./VerifiedModal";
import { tableStyle } from "./tableStyle";
import { getUidFromCookie } from "../../helpers/getUserId";

const moment = require("moment");
const { Content } = Layout;
const { Title } = Typography;
const { Search } = Input;

type DataRow = {};

const VerifiedResellers: React.FC = () => {
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [viewId, setViewId] = useState("");
  const [modalKey, setModalKey] = useState("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const params = { page: 1, perPage: 10, status: "review" };
    getDataUser(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refreshData() {
    const params = { page: 1, perPage: 10, status: "review" };
    getDataUser(params);
    setSearchValue("");
  }

  function getDataUser(params: any) {
    setLoadingData(true);
    query(params).then((response) => {
      var payload = JSON.parse(response.responseBody.Payload);
      if (payload.success) {
        const body = JSON.parse(payload.body);
        setTableData(body.data);
        setTotalData(body.total);
      }
      setLoadingData(false);
    });
  }

  function searchData(value: any) {
    setLoadingData(true);
    setSearchValue(value);
    const params = { q: value, page: 1, perPage: 10, status: "review" };
    query(params)
      .then((response) => {
        var payload = JSON.parse(response.responseBody.Payload);
        if (payload.success) {
          const body = JSON.parse(payload.body);
          setTableData(body.data);
          setTotalData(body.total);
        }
        setLoadingData(false);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  }

  const handlePageChange = (page: any) => {
    const params = {
      page: page,
      perPage: 10,
      search: searchValue,
      status: "review",
    };
    getDataUser(params);
  };

  const handlePerRowsChange = async (data: any) => {
    const params = {
      q: searchValue,
      page: data.page,
      perPage: 10,
      status: "review",
    };
    query(params)
      .then((response) => {
        var payload = JSON.parse(response.responseBody.Payload);
        if (payload.success) {
          const body = JSON.parse(payload.body);
          setTableData(body.data);
          setTotalData(body.total);
        }
        setLoadingData(false);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  };

  async function query(params: any) {
    const myInit = {
      queryStringParameters: params,
      headers: {
        "X-UID": getUidFromCookie(),
      },
    };
    return await API.get("pejuangweb", "/api/upgrade-request/", myInit);
  }

  const tableColumn: TableColumn<DataRow>[] = [
    {
      name: "ID",
      selector: (row: any) => row.userId,
      minWidth: "150px",
    },
    {
      name: "Name",
      selector: (row: any) => row.fullName,
      sortable: true,
      minWidth: "250px",
    },
    {
      name: "Date Request",
      selector: (row: any) => moment(row.requestDate).format("DD-MM-YYYY"),
      minWidth: "150px",
    },
    {
      name: "Status",
      selector: (row: any) => row.status,
      minWidth: "150px",
    },
    {
      name: "Actions",
      minWidth: "200px",
      selector: (row: any) => renderActionButton(row),
    },
  ];

  function renderActionButton(record: any) {
    return (
      <Button
        style={{ marginBottom: 7 }}
        onClick={() => handleViewButton(record)}
      >
        Review
      </Button>
    );
  }

  function handleViewButton(record: any) {
    setModalKey((Math.random() * 1000).toString());
    setViewId(record.id);
    setViewDetailsModal(true);
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content className="pejuang-main-content">
        <Row>
          <Col span={24}>
            <Card bordered={false} className="header-background">
              <Title level={4}>List Virified Reseller Request</Title>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="content-layout">
            <Card>
              <Row>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    type="default"
                    onClick={() => refreshData()}
                    icon={<SyncOutlined />}
                  >
                    Refresh
                  </Button>
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={24}>
                  <Search
                    allowClear
                    placeholder="Input Reseller Name to search.."
                    enterButton="Search"
                    loading={loadingData}
                    onSearch={(value) => searchData(value)}
                  ></Search>
                </Col>
                <Col span={24}>
                  <DataTable
                    columns={tableColumn}
                    data={tableData}
                    progressPending={loadingData}
                    pagination
                    customStyles={tableStyle}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationServer
                    paginationTotalRows={totalData}
                    onChangePage={handlePageChange}
                  />
                </Col>
              </Row>

              <br />
            </Card>
          </Col>
        </Row>
        <VerifiedModal
          isVisible={viewDetailsModal}
          id={viewId}
          key={modalKey}
          onSaveComplete={() => refreshData()}
        />
      </Content>
    </Layout>
  );
};

export default VerifiedResellers;
