import React, {useEffect, useState} from "react";
import {Checkbox, Col, Input, Row, Modal, Alert, Select, Form, Button, Upload, DatePicker, InputNumber} from "antd";
import {API} from "@aws-amplify/api";
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import {ListUplineModal} from "../common/ListUplineModal";
import {getUidFromCookie} from "../../helpers/getUserId";

const { Option } = Select;
const { TextArea } = Input;
const moment = require('moment');

type Props = {
	isVisible : any,
	id : any,
	onSaveComplete : any
}

export const CashbackModal: React.FC<Props> = (props: any) => {
  const [loadingData, setLoadingData] = useState(true);
	const [isVisible, setIsVisible] = useState(props.isVisible);
	const [cashbackData, setCashbackData] = useState<any | null>(null)
	const [allProducts, setAllProducts] = useState<Array<any>>([]);
	const [promoCode, promoCodeChange] = useState("")
	const [description, descriptionChange] = useState("")
	const [startDateForm, startDateFormChange] = useState(moment())
	const [endDateForm, endDateFormChange] = useState(moment())
	const [maxUsage, maxUsageChange] = useState(0)
	const [transactionType, transactionTypeChange] = useState("")
	const [productIds, productIdsChange] = useState<Array<any>>([]);
	const [minimalAmount, minimalAmountChange] = useState(0)
	const [amountType, amountTypeChange] = useState("")
	const [amountValue, amountValueChange] = useState(0)
	const [amountMaxValue, amountMaxValueChange] = useState(0)

	useEffect(() => {
		getCashbackDetail(props.id)
		getProduct()
	}, [])

	useEffect(() => {
		if (cashbackData !== null && props.id) {
			promoCodeChange(cashbackData.id)
			descriptionChange(cashbackData.description)
			startDateFormChange(moment(cashbackData.dateStart))
			endDateFormChange(moment(cashbackData.dateEnd))
			maxUsageChange(cashbackData.maxUsage)
			transactionTypeChange(cashbackData.transactionType)
			productIdsChange(cashbackData.productIds)
			minimalAmountChange(cashbackData.minimalAmount)
			amountTypeChange(cashbackData.amount.type)
			amountValueChange(cashbackData.amount.value)
			amountMaxValueChange(cashbackData.amount.maxValue !== undefined || cashbackData.amount.maxValue !== null ? cashbackData.amount.maxValue : 0)
		}
	}, [cashbackData, props.id])

  function onModalCancelButton() {
		setIsVisible(false);
	}

	function getCashbackDetail(value: any) {
		setLoadingData(true)
		detailCashbackEndpoint(value).then(res => {
			const payload = JSON.parse(res.responseBody.Payload);
      if (payload.success) {
				const body = JSON.parse(payload.body);
				setCashbackData(body);
			}
		}).catch((err: any) => {
			setLoadingData(false)
			alert("Error : " + err)
		})
	}

	function getProduct() {
    queryAllProducts().then(res => {
      const payload = JSON.parse(res.responseBody.Payload)
      if (payload.success) {
        const products = JSON.parse(payload.body);

        let newProviders = {};
        for (let i in products) {
          var product = products[i];
          if (!newProviders.hasOwnProperty(product.provider)) {
            // @ts-ignore
            newProviders[product.provider] = [product];
          } else {
            // @ts-ignore
            newProviders[product.provider].push(product);
          }
        }

        let listProviders = Object.keys(newProviders);
				setAllProducts(listProviders)
			}
    })
	}

	function editCashback() {
		const amount = amountType === "percentage" ?
		{
			type: amountType,
			value: amountValue,
			maxValue: amountMaxValue,
		} :

			{
			value: amountValue,
			maxValue: amountMaxValue,
		}

    const data = {
      description: description,
      maxUsage: maxUsage,
      transactionType: transactionType,
      productIds: productIds,
      minimalAmount: minimalAmount,
      amount,
      promoType: 'cashback',
      dateStart: moment(startDateForm).valueOf(),
      dateEnd: moment(endDateForm).valueOf(),
    }

		updateCashbackEndpoint(data).then(res => {
			var payload = JSON.parse(res.responseBody.Payload);
			if (payload.success) {
				setIsVisible(false);
				props.onSaveComplete();
			} else {
				//show error
			}
		})
	}

	async function detailCashbackEndpoint(params : any) {
		let init = {
			headers: {
				'X-UID': getUidFromCookie()
			}
		};
		return await API.get('pejuangweb', '/api/promo/' + params, init);
	}

	async function queryAllProducts() {
		return API.get('pejuangweb', '/api/product/', {
			headers: {
				'X-UID': getUidFromCookie()
			}
		});
  }

	async function updateCashbackEndpoint(params : any) {
		let init = {
			body: params,
			headers: {
				'X-UID': getUidFromCookie()
			}
		};
		return await API.put('pejuangweb', '/api/promo/' + props.id, init);
	}

  function renderContent() {
		// if (loadingData) {
		// 	return <div>
		// 		<LoadingOutlined style={{ fontSize: 24 }} spin />
		// 	</div>
		// }
		return (
			<div>
				{/* <Row>
					<Alert
						// className={hideErrorClassName}
						message="Error"
						description="Something wrong happened with the system. Please contact administrator."
						type="error"
						showIcon
					/>
				</Row> */}
				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col span={8} className="gutter-row">
						Promo Code
					</Col>
					<Col span={16} className="gutter-row">
						Description:
					</Col>
				</Row>
				<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
					<Col span={8} className="gutter-row">
						<Input value={promoCode} onChange={((event: any) => promoCodeChange(event.target.value))} disabled />
					</Col>
					<Col span={16} className="gutter-row">
            <TextArea autoSize={{ minRows: 2, maxRows: 6 }} value={description} onChange={((event: any) => descriptionChange(event.target.value))} />
					</Col>
        </Row>
        <br />
        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
					<Col span={8} className="gutter-row">
						Date Start
					</Col>
					<Col span={8} className="gutter-row">
						Date End:
					</Col>
					<Col span={8} className="gutter-row">
						Max Usage
					</Col>
				</Row>
				<Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
					<Col span={8} className="gutter-row">
						<DatePicker style={{width: 250}} value={startDateForm} onChange={(dates, dateString) => startDateFormChange(dates)}  />
					</Col>
					<Col span={8} className="gutter-row">
						<DatePicker style={{width: 250}} value={endDateForm} onChange={(dates, dateString) => endDateFormChange(dates)} />
					</Col>
					<Col span={8} className="gutter-row">
						<InputNumber style={{ width: 250 }} value={maxUsage} onChange={((value: any) => maxUsageChange(value))}/>
					</Col>
        </Row>
        <br />
        <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
					<Col span={8} className="gutter-row">
						Minimal Amount
					</Col>
					<Col span={8} className="gutter-row">
						Transaction Type
					</Col>
					<Col span={8} className="gutter-row">
						Products
					</Col>
				</Row>
				<Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
					<Col span={8} className="gutter-row">
						<InputNumber style={{width: 250}} value={minimalAmount} onChange={((value: any) => minimalAmountChange(value))} />
					</Col>
					<Col span={8} className="gutter-row">
						<Select style={{width: 250}} value={transactionType} onChange={((value: any) => transactionTypeChange(value))}>
							<Option value="transaction">Transaction</Option>
							<Option value="disbursement">Disbursement</Option>
							<Option value="topup">Topup</Option>
						</Select>
					</Col>
					<Col span={8} className="gutter-row">
					<Select mode="multiple" allowClear style={{width: "100%"}} value={productIds} onChange={((value: any) => productIdsChange(value))}>
					{
						allProducts.map((data: any, index: any) => {
							return <Option value={data} key={data} x>{data}</Option>
						})
					}
					</Select>
					</Col>
        </Row>
				<br />
				<Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
					<Col span={8} className="gutter-row">
						Amount Type
					</Col>
					<Col span={8} className="gutter-row">
						Amount Value
					</Col>
					<Col span={8} className="gutter-row">
						{amountType === "percentage" && "Amount Max Value"}
					</Col>
				</Row>
				<Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
					<Col span={8} className="gutter-row">
						<Select style={{width: 250}} value={amountType} onChange={(data : any) => amountTypeChange(data)}>
							<Option value="transaction">Transaction</Option>
							<Option value="disbursement">Disbursement</Option>
							<Option value="topup">Topup</Option>
						</Select>
					</Col>
					<Col span={8} className="gutter-row">
						<InputNumber style={{width: 250}} value={amountValue} onChange={((value: any) => amountValueChange(value))} />
					</Col>
					<Col span={8} className="gutter-row">
						{amountType === "percentage" && <InputNumber style={{width: 250}} value={amountMaxValue} onChange={((event: any) => amountMaxValueChange(event.target.value))} />}
					</Col>
        </Row>
        <br />
			</div>
		)
	}

	return (
		<Modal
			width="10"
			style={{marginLeft: '40px', marginRight: '40px'}}
			title="Cashback Detail"
			visible={isVisible}
			onOk={() => editCashback()}
			okText="Update"
			onCancel={() => onModalCancelButton()}
		>
			{renderContent()}
		</Modal>
	)

}
