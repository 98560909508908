import React, {useEffect, useState} from "react";
import { API } from "aws-amplify";
import {Button, Card, Col, Divider, Layout, Popconfirm, Row, Select, Table, Tag, Typography} from "antd";
import {
	SyncOutlined
} from '@ant-design/icons';
import {useHistory} from "react-router";
import 'react-tabulator/lib/styles.css';
import "react-tabulator/lib/css/semantic-ui/tabulator_semantic-ui.min.css";
import {reactFormatter, ReactTabulator} from 'react-tabulator'
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {getUidFromCookie} from "../../helpers/getUserId";

const { Content, Footer } = Layout;
const { Title } = Typography;
const {Option} = Select;

const options = {
	movableRows: true,
	groupBy: "provider",
	groupStartOpen: false,
};


const PriceplanDetails: React.FC = () => {
	const [queryLoading, setQueryLoading] = useState(false);
	const [data, setData] = useState([]);
	const [allProducts, setAllProducts] = useState([]);
	const [providerMapping, setProviderMapping] = useState({});
	const [listProviders, setListProviders] = useState([]);
	const [newProductIdInPricePlan, setNewProductIdInPricePlan] = useState('');
	const [newProviderIdInPricePlan, setNewProviderIdInPricePlan] = useState('');
	const [currentViewId, setCurrentViewId] = useState('DEFAULT');

	const [loadingData, setLoadingData] = useState(false);
	const [viewId, setViewId] = useState('');
	const [modalKey, setModalKey] = useState('');
	const history = useHistory();

	useEffect(() => {
		refreshData();
	}, []);

	function refreshData() {
		setLoadingData(true);
		var promises = [];
		promises.push(queryAllProducts());
		promises.push(queryPricePlan());

		Promise.all(promises).then(data => {
			var item = JSON.parse(data[1].responseBody.Payload).body;
			var allProducts = JSON.parse(JSON.parse(data[0].responseBody.Payload).body);

			var products = JSON.parse(item).products;

			var newProducts = [];
			for (var i in products) {

				let productId = i;
				if (getProductProvider(allProducts, productId) !== null) {
					let product = getProductProvider(allProducts, productId);
					var provider = product.provider;
					newProducts.push({
						id: productId,
						productName: product.name,
						provider: provider,
						sellingPrice: products[i].sellingPrice
					})
				}

			}

			var newProviders = {};
			for (var i in allProducts) {
				var product = allProducts[i];
				if (!newProviders.hasOwnProperty(product.provider)) {
					// @ts-ignore
					newProviders[product.provider] = [product];
				} else {
					// @ts-ignore
					newProviders[product.provider].push(product);
				}
			}

			var listProviders = Object.keys(newProviders);

			//@ts-ignore
			setData(newProducts);
			setAllProducts(allProducts);
			setProviderMapping(newProviders);
			//@ts-ignore
			setListProviders(listProviders);
		});
	}

	function getProductProvider(allProducts: any, productId: any){
		for (var i in allProducts) {
			var product = allProducts[i];
			if (product.id === productId) {
				return product;
			}
		}
		return null;
	}


	async function queryPricePlan() {
		let myInit = {
			headers: {
				'X-UID': getUidFromCookie()
			}
		};
		return await API.get('pejuangweb', '/api/priceplan/' + currentViewId, myInit);
	}

	async function queryAllProducts() {
		return API.get('pejuangweb', '/api/product/', {
			headers: {
				'X-UID': getUidFromCookie()
			}
		});
	}

	async function updatePricePlan(vendorId: any, params: any) {
		var init = {
			body: params,
			headers: {
				'X-UID': getUidFromCookie()
			}
		};
		return API.put('pejuangweb', '/api/priceplan/' + vendorId, init);
	}

	function getModalColumns(){
		return [
			{title: "Product ID", field: "id", width: 200},
			{title: "Product Name", field: "productName", width: 300},
			{
				title: "Selling Price",
				field: "sellingPrice",
				editor: "input",
				formatter: "money",
				formatterParams: {precision: 0, thousand: "."}
			},
			{
				title: "Action",
				field: "action",
				align: "center",
				formatter: reactFormatter(<DeleteButtonFormatter />)
			}
		];
	}

	function handleDeleteProductInPricePlan(item: any) {
		var newProducts: any = [];
		for (var i = 0; i < data.length; i++) {
			if (data[i]["id"] !== item.id) {
				newProducts.push(data[i]);
			}
		}

		setData(newProducts);
	}

	function DeleteButtonFormatter(props: any){
		const cellData = props.cell._cell.row.data;
		return (<Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteProductInPricePlan(cellData)}>
			<Button danger={true} >Delete</Button>
		</Popconfirm>)
	}

	function isProductDisabled(newData: any){
		for (var i in data) {
			var product = data[i];
			if (product["id"] === newData.id) {
				return true;
			}
		}
		return false;
	}

	function handleAddRow() {
		if (newProductIdInPricePlan != '') {
			var products = JSON.parse(JSON.stringify(data));
			let currentProduct = getProductProvider(allProducts, newProductIdInPricePlan);
			products.push({
				id: newProductIdInPricePlan,
				productName: currentProduct.name,
				provider: currentProduct.provider,
				sellingPrice: 0,
				action: undefined
			});
			setData(products);
		}
	}



	function checkIfProductInTable(product: any, listProducts: any) {
		for (var i in listProducts) {
			var currentProduct = listProducts[i];
			if (currentProduct.id == product.id) {
				return true;
			}
		}
		return false;
	}


	function handleAddProvider(){
		if (newProviderIdInPricePlan != '') {
			var products = JSON.parse(JSON.stringify(data));

			// @ts-ignore
			var newProducts = providerMapping[newProviderIdInPricePlan];
			for (var i in newProducts) {
				var product = newProducts[i];

				if (!checkIfProductInTable(product, products)) {
					products.push({
						id: product.id,
						vendorProductId: currentViewId,
						provider: newProviderIdInPricePlan,
						sellingPrice: 0,
						action: undefined
					});
				}
			}
			setData(products);

		}
	}

	function getUpdatedProductPricePlan() {
		var newData: { [key: string]: any; } = {};
		for (let i = 0; i < data.length; i++) {
			newData[data[i]["id"]] = {
				sellingPrice: Number(data[i]["sellingPrice"]),
			};
		}
		return newData;
	}


	function onModalSaveButton(){
		var products = getUpdatedProductPricePlan();
		var params = {
			id: currentViewId,
			name: "Default Pricing",
			products: products
		};
		updatePricePlan(currentViewId, params).then(data => {
			setQueryLoading(true);
			var success = JSON.parse(data.responseBody.Payload).success;
			if (success) {
				refreshData();
			} else {
				setQueryLoading(false);
			}
		}).catch(err => {
			setQueryLoading(false);
		});
	}

	function downloadExcel() {
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const fileExtension = '.xlsx';

		//@ts-ignore
		const ws = XLSX.utils.json_to_sheet(data);
		const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
		const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
		const newData = new Blob([excelBuffer], {type: fileType});
		const newDate = new Date();
		FileSaver.saveAs(newData, currentViewId + "_" + newDate + fileExtension);
	}


	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Content className="pejuang-main-content">
				<Row>
					<Col span={24}>
						<Card bordered={false} className="header-background">
							<Title level={4}>Priceplan</Title>
						</Card>
					</Col>
				</Row>


				<Row>
					<Col span={24} className="content-layout">
						<Card>
							<Row>
								<Col span={9} push={15} style={{textAlign: "right"}}>
									<Button type="primary" onClick={() =>onModalSaveButton()} style={{marginLeft: 20, float: "right"}}>Save</Button>

									<Button onClick={() => downloadExcel()} style={{marginLeft: 20, float: "right"}}>Download
										Excel</Button>
									<Button type="default" onClick={() => refreshData()}  icon={<SyncOutlined />}>
										Refresh
									</Button>
								</Col>
							</Row>
							<br />
							<Row justify="start">

								<Col span={12} style={{marginRight: "20px"}}>
									<Select placeholder="Add New Product ID" defaultValue=""
									        style={{width: '70%', marginBottom: "20px"}} showSearch
									        onChange={(data : any) => setNewProductIdInPricePlan(data)}>
										{
											allProducts.map((data: any, index: any) => {
												return <Option value={data.id}
												               disabled={isProductDisabled(data)}
																key={"products" + data.id}
x												>
													{data.id + " - " + data.name}</Option>
											})
										}
									</Select>
									<Button type="primary" onClick={()=> handleAddRow()}>Add Product</Button>
								</Col>
							</Row>
							<Row>
								<Col span={12}>
									<Select placeholder="Add Provider Group" defaultValue=""
									        style={{width: '70%', marginBottom: "20px"}} showSearch
									        onChange={(data : any) => setNewProviderIdInPricePlan(data)}>
										{
											listProviders.map((data: any, index: any) => {
												return <Option value={data}
												               key ={"provider" + index}
													           disabled={isProductDisabled(data)}>
													{data}</Option>
											})
										}
									</Select>
									<Button type="primary" onClick={() => handleAddProvider()}>Add Provider</Button>
								</Col>
							</Row>
							<br/>
							<ReactTabulator
								// @ts-ignore
								columns={getModalColumns()}
								data={data}
								tooltips={true}
								options={options}
								layout={"fitData"}
							/>
						</Card>
					</Col>
				</Row>
			</Content>
		</Layout>
	);
};

export default PriceplanDetails
