import React, {useEffect, useState} from "react";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Form, Input, Modal, Layout, Popconfirm, Row, Table, Tag, Typography, DatePicker, Select, InputNumber } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {getUidFromCookie} from "../../helpers/getUserId";

const { Content, Footer } = Layout;
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const moment = require('moment');

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};


const CashbackCreate: React.FC = () => {
  const [typeAmount, typeAmountChange] = useState("")
  const [allProducts, setAllProducts] = useState<Array<any>>([]);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    getProduct()
  }, [])

  function getProduct() {
    queryAllProducts().then(res => {
      const payload = JSON.parse(res.responseBody.Payload)
      if (payload.success) {
        const products = JSON.parse(payload.body);

        let newProviders = {};
        for (let i in products) {
          var product = products[i];
          if (!newProviders.hasOwnProperty(product.provider)) {
            // @ts-ignore
            newProviders[product.provider] = [product];
          } else {
            // @ts-ignore
            newProviders[product.provider].push(product);
          }
        }

        let listProviders = Object.keys(newProviders);
				setAllProducts(listProviders)
			}
    })
  }

  const onFinish = (values: any) => {
    const amount =
      typeAmount === "percentage" ?
        {
          type: values.amountType,
          value: parseInt(values.amountValue),
          maxValue: parseInt(values.amountMaxValue),
        } :
        {
          type: values.amountType,
          value: parseInt(values.amountValue),
        }

    const data = {
      id: values.id,
      description: values.description,
      maxUsage: values.maxUsage,
      transactionType: values.transactionType,
      productIds: values.productIds,
      minimalAmount: parseInt(values.minimalAmount),
      amount,
      promoType: 'cashback',
      dateStart: moment(values.dateStart).valueOf(),
      dateEnd: moment(values.dateEnd).valueOf(),
    }

    postCashback(data).then(response => {
      Modal.success({
        content: 'Success',
        onOk : (args: any) => {
          window.location.reload();
        }
      });
      setLoadingData(false)
    }).catch(err => {
      alert("Error : " + err);
    })
  }

  function onFinishFailed(errorInfo : any) {
		console.error('Failed:', errorInfo);
	}

  async function queryAllProducts() {
		return API.get('pejuangweb', '/api/product/', {
          headers: {
            'X-UID': getUidFromCookie()
          }
        });
  }

  const postCashback = async (data: any) => {
    const init = {
      body: data,
      headers: {
        'X-UID': getUidFromCookie()
      }
    }
    return await API.post('pejuangweb', '/api/promo/', init);
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content className="pejuang-main-content">
        <Row>
          <Col span={24}>
            <Card bordered={false} className="header-background">
              <Title level={4}>Add CashBack</Title>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="content-layout left-align">
            <Card>
              {loadingData ?
                (<div> <LoadingOutlined style={{ fontSize: 24 }} spin /> </div>) :
                (<Form
                  name="basic"
                  {...layout}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="Promo Code"
                    name="id"
                    rules={[{ required: true, message: 'Please input promo code!' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Description"
                    name="description"
                  rules={[{ required: true, message: 'Please input description!' }]}
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Start Date"
                    name="dateStart"
                  rules={[{ required: true, message: 'Please input start date!' }]}
                  >
                    <DatePicker />
                  </Form.Item>
                  <Form.Item
                    label="End Date"
                    name="dateEnd"
                  rules={[{ required: true, message: 'Please input end date!' }]}
                  >
                    <DatePicker />
                  </Form.Item>
                  <Form.Item
                    label="Max Usage"
                    name="maxUsage"
                  rules={[{ required: true, message: 'Please input max usage!' }]}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    label="Minimal Amount"
                    name="minimalAmount"
                    rules={[{ required: true, message: 'Please input minimal amount!' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Transaction Type"
                    name="transactionType"
                  rules={[{ required: true, message: 'Please input transaction type!' }]}
                  >
                    <Select>
                      <Option value="transaction">Transaction</Option>
                      <Option value="disbursement">Disbursement</Option>
                      <Option value="topup">Topup</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Amount Type"
                    name="amountType"
                  rules={[{ required: true, message: 'Please input amount type!' }]}
                  >
                    <Select onChange={(data: any) => typeAmountChange(data)}>
                      <Option value="fixed">Fixed</Option>
                      <Option value="percentage">Percentage</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Amount Value"
                    name="amountValue"
                  rules={[{ required: true, message: 'Please input amount value!' }]}
                  >
                    <Input />
                  </Form.Item>
                  {typeAmount === "percentage" && (
                    <Form.Item
                      label="Amount Max Value"
                      name="amountMaxValue"
                      rules={[{ required: typeAmount === "percentage" ? true : false, message: 'Please input amount max value!' }]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  <Form.Item
                    label="Provider"
                    name="productIds"
                    rules={[{ required: true, message: 'Please input provider!' }]}
                  >
                    <Select mode="multiple" allowClear>
                      {
                        allProducts.map((data: any, index: any) => {
                          return <Option value={data} key={data} x>{data}</Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>)}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default CashbackCreate
