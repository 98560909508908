import {
    Button,
    Card,
    Col,
    Descriptions,
    Form,
    Input,
    Modal,
    PageHeader,
    Result,
    Row,
    Select,
    Typography
} from 'antd';
import React, {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import {
    SearchOutlined
} from '@ant-design/icons';
import {ListUplineModal} from "../common/ListUplineModal";
import {getUidFromCookie} from "../../helpers/getUserId";
var moment = require('moment');

const {Option} = Select;
const { Title } = Typography;



type AddBalanceProps = {}

const AddBalance: React.FC<AddBalanceProps> = (props) => {
    const [resellerId, setResellerId] = useState("");
    const [balance, setBalance] = useState("");
    const [note, setNote] = useState("");
    const [userId, setUserId] = useState("");

    const [selectedUplineId, setSelectedUplineId] = useState('');
    const [uplineValue, setUplineValue] = useState('');
    const [uplineModalKey, setUplineModalKey] = useState('');
    const [showUplineModal, setShowUplineModal] = useState(false);

    useEffect(() => {

        Auth.currentUserInfo().then(id => {
            setUserId(id.username);
        })
    }, []);


    function createResellerBalance(resellerId: any, params: any){
        var init = {
            body: params,
            headers: {
                'X-UID': getUidFromCookie()
            }
        };
        return API.put('pejuangweb', '/api/userBalance/' + resellerId, init);
    }


    function onShowUplineModal() {
        setShowUplineModal(true);
        setUplineModalKey((Math.random() * 1000).toString());
    }

    function onSelectUpline(uplineData : any) {
        setSelectedUplineId(uplineData.id);
        setUplineValue(uplineData.id + " - " + uplineData.name);
    }

    function submitAddbalance() {
        if (userId === "" || isNaN(Number(balance))) {
            Modal.error({
                title: 'Error',
                content: "Invalid request, select reseller id or input the balance!",
            });
            return
        }
        let values = {
            balance : Number(balance),
            note : note,
            userId : selectedUplineId,
            user : userId
        };
        // console.log(values);

        Modal.confirm({
            title: 'Confirm Adding balance?',
            content: "Are you sure you want to add balance for " + uplineValue + " with amount " + toCurrency(balance) + " ?",
            onOk() {
                createResellerBalance(selectedUplineId, values).then(data => {
                    // console.log(data);
                    let payload = JSON.parse(data.responseBody.Payload);
                    var success = payload.success;
                    let attr = JSON.parse(payload.body);
                    // console.log(attr);
                    let balance = toCurrency(attr.balance);
                    let currentBalance = toCurrency(attr.currentBalance);
                    let time = moment.utc(attr.requestTimestamp).local().format('DD/MM/YY HH:mm:ss.SSSZ');
                    let resellerName = attr.resellerName;

                    if (success) {
                        Modal.success({
                            onOk() {
                                window.location.reload();
                                },
                            content :
                                <Descriptions title={resellerName} bordered>
                                <Descriptions.Item label="Added Balance" span={3}>{balance}</Descriptions.Item>
                                <Descriptions.Item label="Current Balance" span={3}>{currentBalance}</Descriptions.Item>
                                <Descriptions.Item label="Time" span={3}>{time}</Descriptions.Item>
                            </Descriptions>
                        })
                    } else {
                        Modal.error({
                            title: 'Error',
                            content: "Something wrong happened with the system. Please contact support.",
                        });
                    }
                }).catch(err => {
                    Modal.error({
                        title: 'Error',
                        content: "Something wrong happened with the system. Please contact support.",
                    });
                });
            }
        });
    }

    function toCurrency(numberString: any) {
        let number = parseFloat(numberString);
        return number.toLocaleString('ID');
    };

    return (
        <div>
            <Row style={{textAlign: "left"}}>
                <Col span={24}>
                    <Card bordered={false} className="header-background">
                        <Title level={4}>Add Balance</Title>
                    </Card>
                </Col>
            </Row>
            <Row style={{textAlign: "left"}}>
                <Col span={24} className="content-layout">
                    <Card title="Form" bordered={false}>
                        <Row>
                            <Col span={24} style={{textAlign: "left"}}>
                                <Input style={{width : 400}} disabled value={uplineValue}/>
                                <Button onClick={() => onShowUplineModal()}>Select User</Button>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={12}>
                                <Input
                                    placeholder="Balance"
                                    onChange={(e) => setBalance(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col span={12}>
                                <Input
                                    placeholder="Note"
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop : "20px"}}>
                            <Button type="primary" htmlType="submit" onClick={() => submitAddbalance()}>
                                Save
                            </Button>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <ListUplineModal
                key={uplineModalKey}
                isVisible={showUplineModal}
                onSelectData={(uplineData : any) => onSelectUpline(uplineData)}/>

        </div>
    );
}

export default AddBalance;
