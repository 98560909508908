import * as React from 'react'
import {
	Button,
	Card,
	Checkbox,
	Col,
	DatePicker,
	Descriptions,
	Dropdown,
	Input, Layout,
	Menu,
	Modal,
	PageHeader,
	Row,
	Select,
	Spin, Statistic,
	Switch,
	Table, Tag,
	Typography
} from 'antd';

import {
	DownOutlined,
	SearchOutlined
} from '@ant-design/icons';
import {API} from 'aws-amplify';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import Highlighter from 'react-highlight-words';
import DeclineTransactionModal from "./DeclineTransactionModal";
import ApproveTransactionModal from "./ApproveTransactionModal";
import ReprocessModal from "./ReprocessModal";
import ReprocessTransactionBasedOnVendorModal from "./ReprocessTransactionBasedOnVendorModal";
import {getUidFromCookie} from "../../helpers/getUserId";

const { Content, Footer } = Layout;
const { Title } = Typography;
const CheckboxGroup = Checkbox.Group;
const {Option} = Select;

var moment = require('moment');
var momentTz = require('moment-timezone');

const TIMER_REFRESH = 6000;
const plainOptions = [
	{label: 'Request Date', value: 'requestTimestampString'},
	{label: 'End Date', value: 'endTimestampString'},
	{label: 'Latency', value: 'latency'},
	{label: 'Product', value: 'productId'},
	{label: 'Status', value: 'status'},
	{label: 'RC', value: 'RC'},
	{label: 'Buy', value: 'buyingPrice'},
	{label: 'Sell', value: 'sellingPrice'},
	{label: 'Message', value: 'message'},
	{label: 'Action', value: 'action'},
	{label: 'ResellerBalance', value: 'resellerBalance'},
	{label: 'Vendor', value: 'vendorName'},
	{label: 'PhoneNumber', value: 'phoneNumber'},
	{label: 'Serial Number', value: 'serialNumber'},
	{label: 'Reseller', value: 'resellerPhoneNumber'},
	{label: 'TrxID', value: 'transactionId'},
	{label: 'ResellerTrxId', value: 'resellerTransactionId'},
	{label: 'ResellerName', value: 'resellerName'}
];


class TransactionHistory extends React.Component<any, any> {
	private searchInput: any;

	constructor(props: any) {
		super(props);

		const menu = (record: any) => (
			<Menu onClick={(e) => this.handleActionButton(record, e)}>
				<Menu.Item key="decline">Decline</Menu.Item>
				<Menu.Item key="approve">Approve</Menu.Item>
				<Menu.Item key="status">Reprocess</Menu.Item>
				<Menu.Item key="reprocessVendor">Bulk Reprocess</Menu.Item>
				<Menu.Item key="callback">Send Callback</Menu.Item>
			</Menu>
		);


		const columns = [
			{
				title: 'Request Date/Time',
				dataIndex: 'requestTimestampString',
				sorter: (a: { requestTimestamp: number; }, b: { requestTimestamp: number; }) => a.requestTimestamp - b.requestTimestamp,
				sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
				defaultSortOrder: 'descend',
				render: (text: any, record: any) => (
					<Typography.Text style={{fontSize: this.state.tableFontSize}}>
						{text}
					</Typography.Text>
				)
			},
			{
				title: 'End Date/Time',
				dataIndex: 'endTimestampString',
				sorter: (a: { endTimestamp: number; }, b: { endTimestamp: number; }) => a.endTimestamp - b.endTimestamp,
				sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
				render: (text: any, record: any) => (
					<Typography.Text style={{fontSize: this.state.tableFontSize}}>
						{text}
					</Typography.Text>
				)
			},
			{
				title: 'Latency',
				dataIndex: 'latency',
				sorter: (a: { latency: number; }, b: { latency: number; }) => a.latency - b.latency,
				sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
				...this.getColumnSearchProps('latency'),
				render: (text: any, record: any) => (
					<Typography.Text style={{fontSize: this.state.tableFontSize}}>
						{text}
					</Typography.Text>
				)
			},
			{
				title: 'Status',
				dataIndex: 'status',
				sorter: (a: { status: string; }, b: { status: string; }) => a.status.localeCompare(b.status),
				sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
				...this.getColumnSearchProps('status'),
				render: (isActive: any, record: any) => (
					<span>
						<Tag color={this.getTagColor(record)}>
							{this.getTagMessage(record)}
						</Tag>
					  </span>
				)
			},
			{
				title: 'Action',
				dataIndex: 'action',
				render: (text: any, record: any) => (
					<span>
						<Dropdown overlay={menu(record)} trigger={['click']}>
							<Button type="primary" onClick={e => e.preventDefault()}>
								Action <DownOutlined />
							</Button>
					  </Dropdown>
					</span>
				)
			},
			{
				title: 'PN',
				dataIndex: 'phoneNumber',
				sorter: (a: { phoneNumber: string; }, b: { phoneNumber: string; }) => a.phoneNumber.localeCompare(b.phoneNumber),
				sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
				...this.getColumnSearchProps('phoneNumber'),
				render: (text: any, record: any) => (
					<Typography.Text style={{fontSize: this.state.tableFontSize}}>
						{text}
					</Typography.Text>
				)
			},
			{
				title: 'Product',
				dataIndex: 'productId',
				sorter: (a: { productId: string; }, b: { productId: string; }) => a.productId.localeCompare(b.productId),
				sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
				...this.getColumnSearchProps('productId'),
				render: (text: any, record: any) => (
					<Typography.Text style={{fontSize: this.state.tableFontSize}}>
						{text}
					</Typography.Text>
				)
			},
			// {
			//     title: 'RC',
			//     dataIndex: 'statusCode',
			//     sorter: (a: { RC: string; }, b: { RC: string; }) => a.RC.localeCompare(b.RC),
			//     sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			//     ...this.getColumnSearchProps('RC'),
			//     render: (text: any, record: any) => (
			//         <Typography.Text style={{fontSize: this.state.tableFontSize}}>
			//             {text}
			//         </Typography.Text>
			//     )
			// },
			{
				title: 'Buy',
				dataIndex: 'buyingPrice',
				sorter: (a: { buyingPrice: number; }, b: { buyingPrice: number; }) => a.buyingPrice - b.buyingPrice,
				sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
				...this.getColumnSearchProps('buyingPrice'),
				render: (text: any, record: any) => (
					<Typography.Text style={{fontSize: this.state.tableFontSize}}>
						{this.toCurrency(text)}
					</Typography.Text>
				)
			},
			{
				title: 'Sell',
				dataIndex: 'sellingPrice',
				sorter: (a: { sellingPrice: number; }, b: { sellingPrice: number; }) => a.sellingPrice - b.sellingPrice,
				sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
				...this.getColumnSearchProps('sellingPrice'),
				render: (text: any, record: any) => (
					<Typography.Text style={{fontSize: this.state.tableFontSize}}>
						{this.toCurrency(text)}
					</Typography.Text>
				)
			},
			{
				title: 'TrxID',
				dataIndex: 'transactionId',
				sorter: (a: { transactionId: string; }, b: { transactionId: string; }) => a.transactionId.localeCompare(b.transactionId),
				sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
				...this.getColumnSearchProps('transactionId'),
				render: (text: any, record: any) => (
					<Typography.Text style={{fontSize: this.state.tableFontSize}}>
						{text}
					</Typography.Text>
				)
			},
			{
				title: 'Message',
				dataIndex: 'message',
				sorter: (a: { message: string; }, b: { message: string; }) => a.message.localeCompare(b.message),
				sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
				...this.getColumnSearchProps('message'),
				width: 400,
				render: (text: any, record: any) => (
					<Typography.Text style={{fontSize: this.state.smallTableFontSize}}>
						{text}
					</Typography.Text>
				)
			},
			{
				title: 'Reseller',
				dataIndex: 'resellerPhoneNumber',
				sorter: (a: { resellerPhoneNumber: string; }, b: { resellerPhoneNumber: string; }) => a.resellerPhoneNumber.localeCompare(b.resellerPhoneNumber),
				sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
				...this.getColumnSearchProps('resellerPhoneNumber'),
				render: (text: any, record: any) => (
					<Typography.Text style={{fontSize: this.state.tableFontSize}}>
						{text}
					</Typography.Text>
				)
			},
			{
				title: 'Reseller Name',
				dataIndex: 'resellerName',
				sorter: (a: { resellerName: string; }, b: { resellerName: string; }) => a.resellerName.localeCompare(b.resellerName),
				sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
				...this.getColumnSearchProps('resellerName'),
				render: (text: any, record: any) => (
					<Typography.Text style={{fontSize: this.state.tableFontSize}}>
						{text}
					</Typography.Text>
				)
			},
			{
				title: 'Balance',
				dataIndex: 'resellerBalance',
				sorter: (a: { resellerBalance: number; }, b: { resellerBalance: number; }) => a.resellerBalance - b.resellerBalance,
				sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
				...this.getColumnSearchProps('currentBalance'),
				render: (text: any, record: any) => (
					<Typography.Text style={{fontSize: this.state.tableFontSize}}>
						{this.toCurrency(text)}
					</Typography.Text>
				)
			},
			// {
			//     title: 'SN',
			//     dataIndex: 'serialNumber',
			//     sorter: (a: { serialNumber: string; }, b: { serialNumber: string; }) => a.serialNumber.localeCompare(b.serialNumber),
			//     sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			//     ...this.getColumnSearchProps('serialNumber'),
			//     render: (text: any, record: any) => (
			//         <Typography.Text style={{fontSize: this.state.tableFontSize}}>
			//             {text}
			//         </Typography.Text>
			//     )
			// },
		];

		this.state = {
			timeoutInterval: null,
			dataSource: [],
			filterStatus: "ALL",
			queryLoading: false,
			allColumns: columns,
			columns: columns,
			filterStartTimestamp: moment(),
			filterEndTimestamp: moment(),
			liveMode: true,
			endRangeOpen: false,
			searchText: '',
			searchedColumn: '',
			searchCount: 0,
			diffIds: {},
			smallTableFontSize: 10,
			tableFontSize: 12,
			tableVisible: false,
			checkedList: [],
			indeterminate: true,
			checkAll: false,

			modalKey: '',
			modalType: "",

			productsData: [],
			selectedProductId: '',
			productsDataFetching: true,
			productIdToNameMapping: {},

			selectedPhoneNumber: '',
			resellerSelectedPhoneNumber : "",
			actionRecord: {},
			lastEvaluatedKey: '',
			moreDataLoading: false,

			providers: [],
			providerDataFetching : true
		}
	}


	componentDidMount() {
		this.getAllProducts();
		this.getAllProviders();
	}


	getAllProviders = () => {
		this.queryProviders().then(response => {
			var payload = JSON.parse(response.responseBody.Payload);
			if (payload.success) {
				// console.log(payload.body);
				const body = JSON.parse(payload.body);
				this.setState({
					providers : body,
					providerDataFetching : false
				})
			}
		}).catch(err => {
			console.log(err);
			this.setState({
				providerDataFetching : false
			})
		})
	};

	getTagColor = (record: any) => {
		if (record.status === "SUCCESS") {
			return 'green';
		} else if (record.status === "FAILED"){
			return 'red';
		} else {
			return 'blue';
		}
	};

	getTagMessage = (record: any) => {
		return record.status;
	};

	getAllProducts = () => {
		this.queryAllProducts().then(data => {
			var items = JSON.parse(data.responseBody.Payload).body;
			var mapping = {};
			for (var i in items) {
				var item = items[i];
				// @ts-ignore
				mapping[item.id] = item.name;
			}
			this.setState({
				productsData: items,
				productsDataFetching: false,
				productIdToNameMapping: mapping
			})
		}).catch(err => {
			console.log(err);
		})
	};

	queryProviders = () => {
		let myInit = {
			headers: {
				'X-UID': getUidFromCookie()
			}
		};
		return API.get('pejuangweb', '/api/provider/', myInit);
	};

	queryAllProducts = () => {
		return API.get('pejuangweb', '/api/product/', {
			headers: {
				'X-UID': getUidFromCookie()
			}
		});
	};

	doSendCallback = (record : any) => {
		return API.get('pejuangweb', '/api/transactionCallback/' + record.id, {
			headers: {
				'X-UID': getUidFromCookie()
			}
		});
	};


	handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
		confirm();
		this.setState({
			searchText: selectedKeys[0],
			searchedColumn: dataIndex,
		});
	};

	handleReset = (clearFilters: any) => {
		clearFilters();
		this.setState({searchText: ''});
	};

	getColumnSearchProps = (dataIndex: any) => ({
		// @ts-ignore
		filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
			<div style={{padding: 8}}>
				<Input
					ref={(node: any) => {
						this.searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					style={{width: 188, marginBottom: 8, display: 'block'}}
				/>
				<Button
					type="primary"
					onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
					icon="Search"
					size="small"
					style={{width: 90, marginRight: 8}}
				>

				</Button>
				<Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
					Reset
				</Button>
			</div>
		),
		filterIcon: (filtered: any) => (
			<SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
		),
		onFilter: (value: any, record: any) => {
			if (record[dataIndex] === undefined) {
				return false
			}
			return record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase())
		},
		onFilterDropdownVisibleChange: (visible: any) => {
			if (visible) {
				setTimeout(() => this.searchInput.select());
			}
		},
		render: (text: any) =>
			this.state.searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
					searchWords={[this.state.searchText]}
					autoEscape
					textToHighlight={text.toString()}
				/>
			) : (
				text
			),
	});

	queryTransactions = () => {
		return this.queryTransactionsWithParams(this.getQueryTransactionParams());
	};

	queryTransactionsWithParams = (params: any) => {
		var myInit = {
			headers: {
				'X-UID': getUidFromCookie()
			},
			response: true,
			queryStringParameters: params
		};

		return API.get('pejuangweb', '/api/transaction/', myInit);
	};

	getQueryTransactionParams = () => {
		let queryStringParams: { [key: string]: any } = {};
		queryStringParams["filterStatus"] = this.state.filterStatus;
		queryStringParams["liveMode"] = this.state.liveMode;
		queryStringParams["resellerPhoneNumber"] = this.state.resellerSelectedPhoneNumber;
		queryStringParams["provider"] = this.state.selectedProductId;
		queryStringParams["phoneNumber"] = this.state.selectedPhoneNumber;


		if (!this.state.liveMode) {
			var filterStartTimestamp = this.state.filterStartTimestamp.tz('Asia/Jakarta').startOf('day').valueOf();
			var filterEndTimestamp = this.state.filterEndTimestamp.tz('Asia/Jakarta').endOf('day').valueOf();

			queryStringParams["filterStartTimestamp"] = filterStartTimestamp;
			queryStringParams["filterEndTimestamp"] = filterEndTimestamp;
		}
		return queryStringParams;
	};


	handleActionButton = (record: any, event: any) => {
		this.setState({
			modalKey: Math.random().toString(36),
			modalType: event.key,
			actionRecord: record
		});

		if (event.key === "callback") {
			this.handleSendCallback(record);
		}
	};

	parseTransactionsData(data: any) {
		let payload = JSON.parse(data.responseBody.Payload);

		let body = JSON.parse(payload.body);
		let items = body.Items;
		if (body.hasOwnProperty("LastEvaluatedKey")) {
			this.setState({
				lastEvaluatedKey: body.LastEvaluatedKey
			})
		} else {
			this.setState({
				lastEvaluatedKey: ""
			})
		}

		let diffIds = this.getDiffIdTransactions(items);
		this.setState({
			diffIds: diffIds
		});
		var newData = [];
		for (var i in items) {
			var item = items[i];
			item["key"] = item["id"];

			item["requestTimestampString"] = moment.utc(item["requestTimestamp"]).local().format('DD/MM/YY HH:mm:ss.SSSZ');

			if (item.hasOwnProperty("endTimestamp")) {
				item["endTimestampString"] = moment.utc(item["endTimestamp"]).local().format('DD/MM/YY HH:mm:ss.SSSZ');
				item["latency"] = (Number(item["endTimestamp"]) - Number(item["requestTimestamp"])) / 1000;

			}


			if (this.state.productIdToNameMapping.hasOwnProperty(item["productId"])) {
				item["productName"] = this.state.productIdToNameMapping[item["productId"]];
			}

			newData.push(item);
		}

		return newData;
	}

	getDiffIdTransactions = (items: any) => {
		if (this.state.dataSource.length == 0) {
			return {};
		}
		var oldDatas = JSON.parse(JSON.stringify(this.state.dataSource));
		var oldIds = {};
		for (var i in oldDatas) {
			var oldData = oldDatas[i];
			// @ts-ignore
			oldIds[oldData.id] = "1";
		}

		var newIds = {};
		for (var j in items) {
			var newItem = items[j];
			if (!oldIds.hasOwnProperty(newItem.id)) {
				// @ts-ignore
				newIds[newItem.id] = "1";
			}
		}
		return newIds;
	};

	refreshTransactions = () => {
		if (!this.state.liveMode) {
			this.setState({
				queryLoading : false
			});
			return;
		}
		this.setState({
			queryLoading : true
		});
		this.queryTransactions().then(response => {
			if (response.status == 200) {
				let data = response.data;
				// console.log(data);

				var newData = this.parseTransactionsData(data);
				this.setState({
					dataSource: newData,
					searchCount: newData.length
				})
			}
		}).catch((error: any) => {
			console.log(error.response)
		}).finally(() => {
			if (this.state.liveMode) {
				this.setState({
					timeoutInterval: setTimeout(this.refreshTransactions, TIMER_REFRESH),
					queryLoading : true
				});
			} else {
				this.setState({
					timeoutInterval: null,
					queryLoading : false
				});
			}
			this.setState({
				moreDataLoading: false
			});
		});
	};


	queryTransactionsOnce = () => {
		this.queryTransactions().then(response => {
			if (response.status == 200) {
				let data = response.data;
				var newData = this.parseTransactionsData(data);
				this.setState({
					dataSource: newData,
					searchCount: newData.length
				})
			}
		}).catch(error => {
			console.log(error.response)
		}).finally(() => {
			this.setState({
				timeoutInterval: null,
				queryLoading: false,
				moreDataLoading: false
			});
		});
	};


	onLiveModeChange = (checked: boolean) => {
		if (!checked) {
			this.setState({
				liveMode: checked,
				timeoutInterval: null,
				queryLoading : false
			});
		} else {
			this.setState({
				liveMode: checked,
			});
		}
	};

	componentWillUnmount() {
		this.setState({
			timeoutInterval: null,
			queryLoading: false
		});
	}

	handleFilterChange = (value: string) => {
		this.setState({
			filterStatus: value
		});
	};

	handleQueryData = (value: any) => {
		if (this.state.liveMode) {
			this.refreshTransactions();
		} else {
			this.queryTransactionsOnce()
		}
		this.setState({
			queryLoading: true,
			moreDataLoading: true
		})
	};

	handleOnMoreDataQuery = (value: any) => {
		let queryStringParams = this.getQueryTransactionParams();
		if (this.state.lastEvaluatedKey != "") {
			queryStringParams["lastEvaluatedKey"] = JSON.stringify(this.state.lastEvaluatedKey);
		}
		this.setState({
			moreDataLoading: true
		});
		this.queryTransactionsWithParams(queryStringParams).then(response => {
			if (response.status == 200) {
				let data = response.data;
				var newData = this.parseTransactionsData(data);
				this.setState({
					dataSource: this.state.dataSource.concat(newData),
					searchCount: newData.length + this.state.dataSource.length
				})
			}
			this.setState({
				moreDataLoading: false
			});
		}).catch(err => {
			console.log(err);
			this.setState({
				moreDataLoading: false
			});
		})
	};

	onFilterStartTimestampChange = (value: any) => {
		this.setState({
			filterStartTimestamp: value
		});
	};

	onFilterEndTimestampChange = (value: any) => {
		this.setState({
			filterEndTimestamp: value
		});
	};

	handleStartRangeOpenChange = (open: boolean) => {
		if (!open) {
			this.setState({endRangeOpen: true});
		}
	};

	handleEndRangeOpenChange = (open: boolean) => {
		this.setState({endRangeOpen: open});
	};

	isRowNeedHighlight = (row: any, index: any) => {
		const slaTime = new Date().getTime() - 10*60*1000;
		if (this.state.diffIds.hasOwnProperty(row.id)) {
			return "info-block";
		}

		if (row.buyingPrice > row.sellingPrice) {
			return 'table-row-highlight';
		} else if (Number(row.requestTimestamp) < slaTime && row.status === "PENDING") {
			return 'table-row-need-attention';
		} else {
			return 'table-row-dark'
		}
	};


	toCurrency = (numberString: any) => {
		let number = parseFloat(numberString);
		return number.toLocaleString('ID');
	};


	showModal = () => {
		this.setState({
			tableVisible: true,
		});
	};

	handleOk = (e: any) => {
		var newColumns = [];
		for (var i in this.state.checkedList) {
			var checkedId = this.state.checkedList[i];
			for (var j in this.state.allColumns) {
				var column = this.state.allColumns[j];
				if (column.dataIndex === checkedId) {
					newColumns.push(column);
				}
			}
		}

		this.setState({
			tableVisible: false,
			columns: newColumns
		});
	};

	handleCancel = (e: any) => {
		this.setState({
			tableVisible: false,
		});
	};

	getAllCheckedValue = () => {
		var values = [];
		for (var i in plainOptions) {
			values.push(plainOptions[i].value);
		}
		return values;
	};


	onCheckboxChange = (checkedList: any) => {
		this.setState({
			checkedList,
			indeterminate: !!checkedList.length && checkedList.length < plainOptions.length,
			checkAll: checkedList.length === plainOptions.length,
		});
	};

	onCheckAllChange = (e: any) => {
		this.setState({
			checkedList: e.target.checked ? this.getAllCheckedValue() : [],
			indeterminate: false,
			checkAll: e.target.checked,
		});
	};

	setSelectedProductDataId = (e: any) => {
		this.setState({
			selectedProductId: e
		})
	}

	onChangePhoneNumberFilter = (val: any) => {
		this.setState({
			selectedPhoneNumber: val
		})
	};

	onChangeResellerPhoneNumberFilter = (val: any) => {
		this.setState({
			resellerSelectedPhoneNumber: val
		})
	};


	onTableChange = (pagination: any, filter: any, sorter: any, extra: any) => {
		this.setState({
			searchCount: extra.currentDataSource.length
		})
	};

	handleSendCallback = (record : any) => {
		this.doSendCallback(record).then(data => {
			let payload = JSON.parse(data.responseBody.Payload);
			if (payload.success) {
				Modal.success({
					content : "Successfully send callback for " + this.state.actionRecord.id
				})
			} else {
				Modal.error({
					title: 'Error',
					content: "Failed to send callback",
				});
			}
		}).catch(err => {
			console.log(err);
		})
  };

  downloadExcel = () => {
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const wcols = [
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 800 },
      { wpx: 150 },
      { wpx: 200 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
      { wpx: 150 },
    ]

		//@ts-ignore
    const ws = XLSX.utils.json_to_sheet(this.state.dataSource);
    ws["!cols"] = wcols
		const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
		const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
		const newData = new Blob([excelBuffer], {type: fileType});
		const newDate = new Date();
		FileSaver.saveAs(newData, "TRANSACTIONS_DATA" + "_" + newDate + fileExtension);
	}

  render() {
    // console.log("data", this.state.dataSource)

		let viewVendor = <div></div>;

		if (this.state.modalType === "approve") {
			viewVendor = <ApproveTransactionModal
				key={this.state.modalKey}
				dataSource={this.state.actionRecord}
				visible={true}/>
		} else if (this.state.modalType === "decline") {
			viewVendor = <DeclineTransactionModal
				key={this.state.modalKey}
				dataSource={this.state.actionRecord}
				visible={true}/>
		} else if (this.state.modalType === "status") {
			viewVendor = <ReprocessModal
				key={this.state.modalKey}
				dataSource={this.state.actionRecord}
				visible={true}/>
		} else if (this.state.modalType === "reprocessVendor") {
			viewVendor = <ReprocessTransactionBasedOnVendorModal
				key={this.state.modalKey}
				dataSource={this.state.actionRecord}
				visible={true}/>
		}

		let moreDataButton;
		if (!this.state.liveMode && this.state.lastEvaluatedKey !== "") {
			moreDataButton = <Button type="primary" onClick={this.handleOnMoreDataQuery}
									 loading={this.state.moreDataLoading}
									 style={{float: "right", marginTop: "10px", zIndex: 9999}}
									 className={this.state.moreDataClassName}>View More</Button>
		}

		// @ts-ignore
		return (
			<Layout style={{ minHeight: '100vh' }}>
				<Content className="pejuang-main-content">
					<Row>
						<Col span={24}>
							<Card bordered={false} className="header-background">
								<Title level={4}>Live Transactions</Title>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col span={24} className="content-layout">
							<Row gutter={[10, 10]} style={{margin: "0"}}>
									<Col span={24} style={{textAlign: 'left'}}>
										<Card bordered={false} size="small" bodyStyle={{padding: "10px"}}>
											<Row justify="space-between" gutter={[20, 20]}>
												<Col span={3}>
													<Switch onChange={this.onLiveModeChange} checkedChildren="Live Mode On"
															unCheckedChildren="Live mode Off" defaultChecked={true}/>
												</Col>
											</Row>
											<Row gutter={[20, 20]}>
												<Col span={24}>
													<DatePicker
														showTime
														format="DD/MM/YYYY"
														value={this.state.filterStartTimestamp}
														placeholder="Start"
														disabled={this.state.liveMode}
														onChange={this.onFilterStartTimestampChange}
														onOpenChange={this.handleStartRangeOpenChange}
													/>
													<DatePicker
														showTime
														format="DD/MM/YYYY"
														value={this.state.filterEndTimestamp}
														placeholder="End"
														disabled={this.state.liveMode}
														onChange={this.onFilterEndTimestampChange}
														open={this.state.endRangeOpen}
														onOpenChange={this.handleEndRangeOpenChange}
													/>
													<Select defaultValue="ALL" style={{width: 150, marginRight: 20, marginLeft: 20}}
															onChange={this.handleFilterChange}>
														<Option value="PENDING">Pending</Option>
														<Option value="SUCCESS">Success</Option>
														<Option value="FAILED">Failed</Option>
														<Option value="ALL">ALL</Option>
                        </Select>
                          <Button onClick={this.showModal} style={{marginRight: 20, float: "right"}}>Table Columns</Button>
                          <Button style={{marginRight: 20, float: "right"}} onClick={this.downloadExcel} >Download Excel</Button>
												</Col>
											</Row>
											<Row gutter={[20, 20]}>
												<Col span={24}>
													<Select style={{width: 200, marginRight: "10px"}} showSearch
															disabled={this.state.liveMode}
															loading={this.state.providerDataFetching}
															allowClear
															placeholder="Provider"
															filterOption={(input: any, option: any) =>
																option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
															}
															onChange={(e: any) => this.setSelectedProductDataId(e)}>
														{
															this.state.providers.map((data: any, index: any) => {
																// @ts-ignore
																return <Option value={data.id}>{data.id}</Option>
															})
														}
													</Select>
													<Input placeholder="Reseller Phone Number"
														   onChange={(e) => this.onChangeResellerPhoneNumberFilter(e.target.value)}
														   disabled={this.state.liveMode}
														   style={{width: 200, marginRight: "10px"}}/>

													<Input placeholder="Phone Number"
														   onChange={(e) => this.onChangePhoneNumberFilter(e.target.value)}
														   disabled={this.state.liveMode}
														   style={{width: 200, marginRight: "10px"}}/>
													<Button type="primary" onClick={this.handleQueryData}
															loading={this.state.queryLoading}>Query</Button>
												</Col>
											</Row>
										</Card>
									</Col>
								<Col span={24}>
									<Card bordered={false}
										  style={{margin: 0}}
										  bodyStyle={{
											  paddingLeft: "15px",
											  paddingRight: "15px",
											  paddingBottom: "10px",
											  paddingTop: "20px"
										  }}>
										{moreDataButton}
										<Table
											rowKey={record => record.id}
											//@ts-ignore
											pagination={{
												size: "small",
												showQuickJumper: true,
												position: ["bottomRight",],
												pageSize: 25,
												total: this.state.searchCount,
												showTotal: (total: any) => `Total ${total} items`
											}}
											size="small"
											bordered
											rowClassName={(record: any, index: any) => this.isRowNeedHighlight(record, index)}
											scroll={{x: true}}
											dataSource={this.state.dataSource}
											columns={this.state.columns}
											onChange={this.onTableChange}
										/>
									</Card>
								</Col>
              </Row>
							<Modal
								title="Table Columns"
								visible={this.state.tableVisible}
								onOk={this.handleOk}
								onCancel={this.handleCancel}
							>
								<div>
									<div style={{borderBottom: '1px solid #E9E9E9'}}>
										<Checkbox
											indeterminate={this.state.indeterminate}
											onChange={this.onCheckAllChange}
											checked={this.state.checkAll}
										>
											Check all
										</Checkbox>
									</div>
									<br/>
									<CheckboxGroup
										options={plainOptions}
										value={this.state.checkedList}
										onChange={this.onCheckboxChange}
									/>
								</div>
							</Modal>
							{viewVendor}
						</Col>
					</Row>
				</Content>
			</Layout>
		);
	}
}

export default TransactionHistory;
