import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import {
  Button,
  Card,
  Col,
  Layout,
  Popconfirm,
  Row,
  Typography,
  Modal,
} from "antd";
import { SyncOutlined, PlusCircleOutlined } from "@ant-design/icons";
import DataTable, { TableColumn } from "react-data-table-component";
import { tableStyle } from "../../helpers/StyleTable";
import { useHistory } from "react-router-dom";
import { UserModal } from "./ModalUser";
import {getUidFromCookie} from "../../helpers/getUserId";

const { Content } = Layout;
const { Title } = Typography;

type DataRow = {};

const UsersList = () => {
  const history = useHistory();

  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [loadingData, setLoadingData] = useState(false);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [viewId, setViewId] = useState("");
  const [modalKey, setModalKey] = useState("");
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const params = { page: 1, perPage: 10 };
    getDataUser(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refreshData() {
    const params = { page: 1, perPage: 10, status: "review" };
    getDataUser(params);
    setSearchValue("");
  }

  function getDataUser(params: any) {
    setLoadingData(true);
    queryGetAdminData(params).then((response) => {
      var payload = JSON.parse(response.responseBody.Payload);
      if (payload.success) {
        const body = JSON.parse(payload.body);
        setTableData(body.data);
        setTotalData(body.total);
      }
      setLoadingData(false);
    });
  }

  function deleteAdmin(value: any) {
    deleteAdminEndpoint(value)
      .then((response) => {
        Modal.success({
          content: "Success",
          onOk: (args: any) => {
            window.location.reload();
          },
        });
      })
      .catch((err) => alert("Error : " + err));
  }

  const handlePageChange = (page: any) => {
    const params = {
      page: page,
      perPage: 10,
      search: searchValue,
      status: "review",
    };
    getDataUser(params);
  };

  const handlePerRowsChange = async (data: any) => {
    const params = {
      q: searchValue,
      page: data.page,
      perPage: 10,
      status: "review",
    };
    queryGetAdminData(params)
      .then((response) => {
        var payload = JSON.parse(response.responseBody.Payload);
        if (payload.success) {
          const body = JSON.parse(payload.body);
          setTableData(body.data);
          setTotalData(body.total);
        }
        setLoadingData(false);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  };

  async function queryGetAdminData(params: any) {
    const myInit = {
      queryStringParameters: params,
      headers: {
        'X-UID': getUidFromCookie()
      }
    };
    return await API.get("pejuangweb", "/api/admin-user/", myInit);
  }

  async function deleteAdminEndpoint(params: any) {
    let init = {
      headers: {
        'X-UID': getUidFromCookie()
      }
    };
    return await API.del("pejuangweb", "/api/admin-user/" + params, init);
  }

  const tableColumn: TableColumn<DataRow>[] = [
    {
      name: "ID",
      selector: (row: any) => row.id,
      width: "259px",
    },
    {
      name: "Username",
      selector: (row: any) => row.username,
      width: "250px",
    },
    {
      name: "Email",
      selector: (row: any) => row.email,
      width: "250px",
    },
    {
      name: "Role",
      selector: (row: any) => row.roleId,
      width: "200px",
    },
    {
      name: "Actions",
      width: "200px",
      selector: (row: any) => renderActionButton(row),
    },
  ];

  function renderActionButton(record: any) {
    return (
      <div className="flex-column">
        <Button
          style={{ marginBottom: 10 }}
          onClick={() => handleViewButton(record)}
        >
          Change Role
        </Button>
        <Popconfirm
          title="Sure to delete data?"
          onConfirm={() => deleteAdmin(record.id)}
        >
          <Button danger>Delete</Button>
        </Popconfirm>
      </div>
    );
  }

  function handleViewButton(record: any) {
    setModalKey((Math.random() * 1000).toString());
    setViewId(record.id);
    setViewDetailsModal(true);
  }

  function redirectToAddAdmin() {
    history.push("/create-users");
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content className="pejuang-main-content">
        <Row>
          <Col span={24}>
            <Card bordered={false} className="header-background">
              <Title level={4}>List User Role</Title>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="content-layout">
            <Card>
              <Row>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    style={{ marginRight: "20px" }}
                    onClick={() => redirectToAddAdmin()}
                  >
                    Create Admin
                  </Button>
                  <Button
                    type="default"
                    onClick={() => refreshData()}
                    icon={<SyncOutlined />}
                  >
                    Refresh
                  </Button>
                </Col>
              </Row>
              <br />
              <Row>
                <Col span={24}>
                  <DataTable
                    columns={tableColumn}
                    data={tableData}
                    progressPending={loadingData}
                    pagination
                    customStyles={tableStyle}
                    onChangeRowsPerPage={handlePerRowsChange}
                    paginationServer
                    paginationTotalRows={totalData}
                    onChangePage={handlePageChange}
                  />
                </Col>
              </Row>

              <br />
            </Card>
          </Col>
        </Row>
        <UserModal
          isVisible={viewDetailsModal}
          id={viewId}
          key={modalKey}
          onSaveComplete={() => refreshData()}
        />
      </Content>
    </Layout>
  );
};

export default UsersList;
