import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Typography,
  Select,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getUidFromCookie } from "../../helpers/getUserId";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const CreateRole: React.FC = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [allPermission, setAllPermission] = useState<Array<any>>([]);

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getPermissions() {
    queryAllPermission().then((res) => {
      const payload = JSON.parse(res.responseBody.Payload);
      if (payload.success) {
        const permission = JSON.parse(payload.body);
        setAllPermission(permission);
      }
    });
  }

  const onFinish = (values: any) => {
    setLoadingData(true);
    postRole(values)
      .then((res: any) => {
        Modal.success({
          content: "Success",
          onOk: (args: any) => {
            window.location.reload();
          },
        });
        setLoadingData(false);
      })
      .catch((err: any) => {
        alert("Error : " + err);
        setLoadingData(false);
      });
  };

  const postRole = async (data: any) => {
    const myInit = {
      body: data,
      headers: {
        "X-UID": getUidFromCookie(),
      },
    };
    return await API.post("pejuangweb", "/api/role/", myInit);
  };

  async function queryAllPermission() {
    return API.get("pejuangweb", "/api/permission/", {
      headers: {
        "X-UID": getUidFromCookie(),
      },
    });
  }

  function onFinishFailed(errorInfo: any) {
    alert("Error : " + errorInfo);
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content className="pejuang-main-content">
        <Row>
          <Col span={24}>
            <Card bordered={false} className="header-background">
              <Title level={4}>Add Role</Title>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="content-layout left-align">
            <Card>
              {loadingData ? (
                <div>
                  {" "}
                  <LoadingOutlined style={{ fontSize: 24 }} spin />{" "}
                </div>
              ) : (
                <Form
                  name="basic"
                  {...layout}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="Role Id"
                    name="id"
                    rules={[
                      { required: true, message: "Please input Role ID!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: "Please input name!" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Permission"
                    name="permissions"
                    rules={[
                      { required: true, message: "Please input permission!" },
                    ]}
                  >
                    <Select mode="multiple" allowClear>
                      {allPermission.map((data: any, index: any) => {
                        return (
                          <Option value={data.id} key={data.id} x>
                            {data.id}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default CreateRole;
