import React, {useEffect, useState} from "react";
import {API} from "@aws-amplify/api";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Modal, Layout, Popconfirm, Row, Table, Typography } from "antd";
import { SyncOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { BannersModal } from "./BannersModal";
import {getUidFromCookie} from "../../helpers/getUserId";
const moment = require('moment');

const { Content } = Layout;
const { Title } = Typography;
const BannersList: React.FC = () => {
  const history = useHistory();

  const [viewDetailsModal, setViewDetailsModal] = useState(false);
	const [viewId, setViewId] = useState('');
  const [modalKey, setModalKey] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const [dataBanners, setDataBanners] = useState([]);


  useEffect(() => {
    getDataBanner()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getDataBanner() {
    setLoadingData(true)
    getBannerEndpoint({}).then(response => {
			const payload = JSON.parse(response.responseBody.Payload);
      if (payload.success) {
				const body = JSON.parse(payload.body);
				setDataBanners(body.data);
			}
			setLoadingData(false);
		});
  }

  function deleteBanner(value: any) {
    deleteBannerEndpoint(value).then(response => {
      Modal.success({
        content: 'Success',
        onOk : (args: any) => {
          window.location.reload();
        }
      });
    }).catch(err => alert("Error : " + err))
  }

  async function getBannerEndpoint(params : any) {
		let init = {
          headers: {
            'X-UID': getUidFromCookie()
          }
        };
		return await API.get('pejuangweb', '/api/banner/', init);
  }

  async function deleteBannerEndpoint(params : any) {
		let init = {
          headers: {
            'X-UID': getUidFromCookie()
          }
        };
		return await API.del('pejuangweb', '/api/banner/' + params, init);
  }

  function sucessEdit() {
    getDataBanner()
  }

  const columns = [
    {
      title: "Image",
      dataIndex: "imageUrl",
      width: 300,
      render: (imageUrl: any) => (
        <div className="banner-image-table">
          <img src={imageUrl} alt="banner_image"/>
        </div>
      )
    },
    {
      title: "Title",
      dataIndex: "title",
      width: 250,
    },
    {
      title: "Subtitle",
      dataIndex: "subtitle",
      width: 300,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 400,
    },
    {
      title: "Period",
      dataIndex: "period",
      width: 150,
    },
    {
      title: "Start Date",
      dataIndex: "periodStart",
      width: 150,
      render: (periodStart: any) => (
        <p>{moment(periodStart).format("DD-MM-YYYY")}</p>
      )
    },
    {
      title: "End Date",
      dataIndex: "periodEnd",
      width: 150,
      render: (periodEnd: any) => (
        <p>{moment(periodEnd).format("DD-MM-YYYY")}</p>
      )
    },
    {
      title: "Actions",
      render: (text: any, record: any) => (
				renderActionButton(record)
			),
    },
  ]

  function renderActionButton(record : any){
    return (
      <div className="flex-column">
        <Button style={{ marginBottom: 10 }} onClick={() => handleViewButton(record)}>View</Button>
        <Popconfirm title="Sure to delete data?" onConfirm={() => deleteBanner(record.id)}>
          <Button danger>Delete</Button>
        </Popconfirm>
      </div>
    )
  }

  function handleViewButton(record : any) {
		setModalKey((Math.random() * 1000).toString());
		setViewId(record.id);
		setViewDetailsModal(true);
	}

  function redirectToAddBanners() {
    history.push("/promo/create-banner")
  }


  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content className="pejuang-main-content">
        <Row>
					<Col span={24}>
						<Card bordered={false} className="header-background">
							<Title level={4}>Banners</Title>
						</Card>
					</Col>
        </Row>
        <Row>
          <Col span={24} className="content-layout">
            <Card>
              <Row>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button type="primary" icon={<PlusCircleOutlined />} style={{marginRight: "20px"}} onClick={() => redirectToAddBanners()}>
										Add Banner
									</Button>
									<Button type="default" icon={<SyncOutlined />} onClick={() => getDataBanner()}>
										Refresh
									</Button>
                </Col>
                <Col span={24} style={{marginTop: "20px"}}>
                  <Table
                    size={"small"}
                    loading={loadingData}
                    bordered
                    scroll={{x: true}}
                    dataSource={dataBanners}
                    // @ts-ignore
                    columns={columns}
                  />
                </Col>
              </Row>
              <Row></Row>
            </Card>
          </Col>
        </Row>
      </Content>
      <BannersModal isVisible={viewDetailsModal} id={viewId} key={modalKey} onSaveComplete={() => sucessEdit()}/>
    </Layout>
  )
}

export default BannersList
