/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Checkbox, Col, Input, Row, Modal, Alert, Select, Button } from "antd";
import { API } from "@aws-amplify/api";
import { LoadingOutlined } from "@ant-design/icons";
import { ListUplineModal } from "../common/ListUplineModal";
import { getUidFromCookie } from "../../helpers/getUserId";

const { Option } = Select;

type Props = {
  isVisible: any;
  id: any;
  onSaveComplete: any;
};

export const ResellerDetailsModal: React.FC<Props> = (props) => {
  const [loadingData, setLoadingData] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [isVisible, setIsVisible] = useState(props.isVisible);
  const [hideErrorClassName, setHideErrorClassName] = useState("hide");

  const [currentId, setCurrentId] = useState(props.id);
  const [name, setName] = useState("");
  const [telegramId, setTelegramId] = useState("");
  const [whatsappId, setWhatsappId] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [uplineId, setSelectedUplineId] = useState("");
  const [userRole, setUserRole] = useState("");

  const [uplineValue, setUplineValue] = useState("");
  const [uplineModalKey, setUplineModalKey] = useState("");
  const [showUplineModal, setShowUplineModal] = useState(false);

  const [isActive, setIsActive] = useState(false);

  function onModalSaveButton() {
    setLoadingSave(true);
    let params = {
      id: currentId,
      name: name,
      isActive,
      phoneNumber,
      email,
      address,
      uplineId,
      userRole,
    };
    updateUser(currentId, params)
      .then((response) => {
        var payload = JSON.parse(response.responseBody.Payload);
        if (payload.success) {
          setIsVisible(false);
          props.onSaveComplete();
        } else {
          //show error
        }
        setLoadingSave(false);
      })
      .catch((err) => {
        setLoadingSave(false);
        console.error("Error : " + JSON.stringify(err));
      });
  }

  function onModalCancelButton() {
    setIsVisible(false);
  }

  useEffect(() => {
    doQueryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function doQueryData() {
    setLoadingData(true);
    queryUser(props.id)
      .then((response) => {
        console.log(response);
        var payload = JSON.parse(response.responseBody.Payload);
        if (payload.success) {
          const body = JSON.parse(payload.body);

          console.log(body);
          setName(body.name);
          setTelegramId(body.telegramId);
          setWhatsappId(body.whatsappId);
          setPhoneNumber(body.phoneNumber);
          setEmail(body.email);
          setAddress(body.address);
          setUplineValue(body.uplineId + " - " + body.uplineName);
          setIsActive(body.isActive);
          setUserRole(body.userRole);
        }
      })
      .finally(() => {
        setLoadingData(false);
      });
  }

  async function updateUser(id: any, params: any) {
    var init = {
      body: params,
      headers: {
        "X-UID": getUidFromCookie(),
      },
    };
    return API.put("pejuangweb", "/api/user/" + id, init);
  }

  function onSelectUpline(uplineData: any) {
    setSelectedUplineId(uplineData.id);
    setUplineValue(uplineData.id + " - " + uplineData.name);
  }

  async function queryUser(id: any) {
    let myInit = {
      headers: {
        "X-UID": getUidFromCookie(),
      },
    };
    return await API.get("pejuangweb", "/api/user/" + id, myInit);
  }

  function onShowUplineModal() {
    setShowUplineModal(true);
    setUplineModalKey((Math.random() * 1000).toString());
  }

  function renderUserRole() {
    let res = [];
    res.push(<Option value="STANDARD">Standard</Option>);
    res.push(<Option value="SALES">Sales</Option>);
    return res;
  }

  function renderContent() {
    if (loadingData) {
      return (
        <div>
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    }
    return (
      <div>
        <Row>
          <Alert
            className={hideErrorClassName}
            message="Error"
            description="Something wrong happened with the system. Please contact administrator."
            type="error"
            showIcon
          />
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={5} className="gutter-row">
            ID
          </Col>
          <Col span={5} className="gutter-row">
            ID Telegram
          </Col>
          <Col span={5} className="gutter-row">
            ID Whatsapp
          </Col>
          <Col span={6} className="gutter-row">
            Nama
          </Col>
          <Col span={3} className="gutter-row">
            Is Active
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={5} className="gutter-row">
            <Input placeholder="ID" id="idInput" value={currentId} disabled />
          </Col>
          <Col span={5} className="gutter-row">
            <Input
              placeholder="Telegram ID"
              value={telegramId}
              disabled
              onChange={(event) => setTelegramId(event.target.value)}
            />
          </Col>
          <Col span={5} className="gutter-row">
            <Input
              placeholder="Whatsapp ID"
              value={whatsappId}
              disabled
              onChange={(event) => setWhatsappId(event.target.value)}
            />
          </Col>
          <Col span={6} className="gutter-row">
            <Input
              placeholder="Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </Col>
          <Col span={3} className="gutter-row">
            <Checkbox
              onChange={(event) => setIsActive(event.target.checked)}
              checked={isActive}
            />
          </Col>
        </Row>
        <br />
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={5} className="gutter-row">
            Phone Number
          </Col>
          <Col span={5} className="gutter-row">
            Email
          </Col>
          <Col span={5} className="gutter-row">
            Alamat
          </Col>
          <Col span={9} className="gutter-row">
            Upline ID
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={5} className="gutter-row">
            <Input
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
            />
          </Col>
          <Col span={5} className="gutter-row">
            <Input
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Col>
          <Col span={5} className="gutter-row">
            <Input
              placeholder="Alamat"
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </Col>
          <Col span={9} className="gutter-row">
            <Row>
              <Col span={10}>
                <Input placeholder="Upline ID" value={uplineValue} disabled />
              </Col>
              <Col span={4}>
                <Button onClick={() => onShowUplineModal()}>
                  Change Upline
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8} className="gutter-row">
            User Role
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8} className="gutter-row">
            <Select
              allowClear
              value={userRole}
              onChange={(value) => setUserRole(value)}
            >
              {renderUserRole()}
            </Select>
          </Col>
        </Row>
        <br />
        <ListUplineModal
          key={uplineModalKey}
          isVisible={showUplineModal}
          onSelectData={(uplineData: any) => onSelectUpline(uplineData)}
        />
      </div>
    );
  }

  return (
    <Modal
      width="10"
      style={{ marginLeft: "40px", marginRight: "40px" }}
      title="Reseller Details"
      visible={isVisible}
      onOk={() => onModalSaveButton()}
      okText="Save"
      confirmLoading={loadingSave}
      onCancel={() => onModalCancelButton()}
    >
      {renderContent()}
    </Modal>
  );
};
