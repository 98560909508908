import React, {useEffect, useState} from "react";
import {Alert, Descriptions, Input, Modal} from "antd";
import {API} from "aws-amplify";
import {getUidFromCookie} from "../../helpers/getUserId";

const {TextArea} = Input;

type ApproveTransactionModalProps = {
    dataSource: any
    visible: any
}

const ReprocessModal: React.FC<ApproveTransactionModalProps> = (props) => {
    const [queryLoading, setQueryLoading] = useState(false);
    const [actionRecord, setActionRecord] = useState(props.dataSource);
    const [modalVisible, setModalVisible] = useState(props.visible);
    const [errorMessage, setErrorMessage] = useState('');
    const [hideErrorClassName, setHideErrorClassName] = useState('hide');
    const [transactionId, setTransactionId] = useState(props.dataSource.hasOwnProperty("transactionId") ? props.dataSource.transactionId : '');
    const [phoneNumber, setPhoneNumber] = useState(props.dataSource.hasOwnProperty("phoneNumber") ? props.dataSource.phoneNumber : '');
    const [status, setStatus] = useState(props.dataSource.hasOwnProperty("status") ? props.dataSource.status : '');

    useEffect(() => {
    }, []);


    function handleApproveTransaction() {
        setHideErrorClassName('hide');

        var message = "";
        if (actionRecord.hasOwnProperty("message")) {
            message = actionRecord.message;
        }

        setQueryLoading(true);
        updateTransactionManual(actionRecord)
            .then(data => {
                var payload = (JSON.parse(data.responseBody.Payload));
                if (payload.success) {
                    setModalVisible(false);
                } else {
                    setErrorMessage(payload.body);
                    setHideErrorClassName('show');
                }
            }).catch(err => {
            setErrorMessage("Something wrong with the system. Please contact the administrator.");
            setHideErrorClassName('show');

        }).finally(() => {
            setQueryLoading(false);
        })
    }

    async function updateTransactionManual(record: any) {

        let myInit = {
            headers: {
                'X-UID': getUidFromCookie()
            }
        };

        return API.get('pejuangweb', '/api/transactionsIdProcess/' + record.id, myInit);
    }

    function handleCancel() {
        setModalVisible(false);
    }


    // @ts-ignore
    return (
        <div>

            <Modal
                title="Reprocess Transaction"
                visible={modalVisible}
                confirmLoading={queryLoading}
                onOk={() => handleApproveTransaction()}
                onCancel={() => handleCancel()}>
                <div>
                    <Alert
                        message={errorMessage}
                        type="error"
                        className={hideErrorClassName}
                    />
                    <br/>
                    <Descriptions size="small" layout="vertical" bordered>
                        <Descriptions.Item label="TransactionID">{transactionId}</Descriptions.Item>
                        <Descriptions.Item label="PhoneNumber">{phoneNumber}</Descriptions.Item>
                        <Descriptions.Item label="Status">{status}</Descriptions.Item>
                    </Descriptions>

                </div>
            </Modal>
        </div>
    );
};


export default ReprocessModal;
