import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Typography,
  Select,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Cookies from "universal-cookie";
import { getUidFromCookie } from "../../helpers/getUserId";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};

const CreateUser: React.FC = () => {
  const cookies = new Cookies();

  const [loadingData, setLoadingData] = useState(false);
  const [allRole, setAllRole] = useState<Array<any>>([]);

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getRoles() {
    queryAllRole().then((response) => {
      const payload = JSON.parse(response.responseBody.Payload);
      if (payload.success) {
        const body = JSON.parse(payload.body);
        setAllRole(body);
      }
    });
  }

  const onFinish = (values: any) => {
    setLoadingData(true);
    postUser(values)
      .then((res: any) => {
        Modal.success({
          content: "Success",
          onOk: (args: any) => {
            window.location.reload();
          },
        });
        setLoadingData(false);
      })
      .catch((err: any) => {
        alert("Error : " + err);
        setLoadingData(false);
      });
  };

  const postUser = async (data: any) => {
    const myInit = {
      headers: {
        "X-UID": getUidFromCookie(),
      },
      body: data,
    };
    return await API.post("pejuangweb", "/api/admin-user/", myInit);
  };

  async function queryAllRole() {
    const myInit = {
      headers: { "X-UID": `${cookies.get("userId")}` },
      queryStringParameters: { page: 1, perPage: 50 },
    };
    return API.get("pejuangweb", "/api/role/", myInit);
  }

  function onFinishFailed(errorInfo: any) {
    alert("Error : " + errorInfo);
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content className="pejuang-main-content">
        <Row>
          <Col span={24}>
            <Card bordered={false} className="header-background">
              <Title level={4}>Add User</Title>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="content-layout left-align">
            <Card>
              {loadingData ? (
                <div>
                  {" "}
                  <LoadingOutlined style={{ fontSize: 24 }} spin />{" "}
                </div>
              ) : (
                <Form
                  name="basic"
                  {...layout}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      { required: true, message: "Please input username!" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Please input email!" }]}
                  >
                    <Input type="email" />
                  </Form.Item>
                  <Form.Item
                    label="Role"
                    name="roleId"
                    rules={[{ required: true, message: "Please select role!" }]}
                  >
                    <Select allowClear>
                      {allRole.map((data: any, index: any) => {
                        return (
                          <Option value={data.id} key={data.id} x>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default CreateUser;
