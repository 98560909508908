import React, {useEffect, useState} from "react";
import {
	Button,
	Card,
	Col,
	DatePicker,
	Descriptions, Form,
	Input,
	PageHeader,
	Row,
	Select, Statistic,
	Table,
	Typography
} from "antd";

import {
	SearchOutlined
} from '@ant-design/icons';

import {API} from "aws-amplify";
import Highlighter from "react-highlight-words";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {ListUplineModal} from "../common/ListUplineModal";
import {getUidFromCookie} from "../../helpers/getUserId";

var moment = require('moment');
const { Title } = Typography;
const {RangePicker} = DatePicker;
const {Option} = Select;

type BalanceMutationProps = {}

const MutationCommission: React.FC<BalanceMutationProps> = (props) => {
	const [loadingData, setLoadingData] = useState(false);
	const [inboxData, setInboxData] = useState([]);
	const [searchInput, setSearchInput] = useState<any | null>(null);
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchColumn] = useState('');
	const [filterStartTimestamp, onFilterStartTimestampChange] = useState(moment());
	const [filterEndTimestamp, onFilterEndTimestampChange] = useState(moment());
	const [queryLoading, setQueryLoading] = useState(false);
	const [resellersData, setResellerData] = useState([]);
	const [currentBalance, setCurrentBalance] = useState('');
	const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
	const [moreDataLoading, setMoreDataLoading] = useState(false);
	const [transactionIdInput, onInputTransactionId] = useState("");

	const [selectedUplineId, setSelectedUplineId] = useState('');
	const [uplineValue, setUplineValue] = useState('');
	const [uplineModalKey, setUplineModalKey] = useState('');
	const [showUplineModal, setShowUplineModal] = useState(false);

	useEffect(() => {
	}, []);


	function handleSearch(selectedKeys: any, confirm: any, dataIndex: any) {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchColumn(dataIndex);
	}

	function handleReset(clearFilters: any) {
		clearFilters();
		setSearchText('');
	}


	const getColumnSearchProps = (dataIndex: any) => ({
		// @ts-ignore
		// @ts-ignore
		filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
			<div style={{padding: 8}}>
				<Input
					ref={(node: any) => {
						setSearchInput(node);
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{width: 188, marginBottom: 8, display: 'block'}}
				/>
				<Button
					type="primary"
					onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
					icon="search"
					size="small"
					style={{width: 90, marginRight: 8}}
				>
					Search
				</Button>
				<Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
					Reset
				</Button>
			</div>
		),
		filterIcon: (filtered: any) => (
			<SearchOutlined />
		),
		onFilter: (value: any, record: any) => {
			if (record[dataIndex] === undefined) {
				return false
			}
			return record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase())
		},
		onFilterDropdownVisibleChange: (visible: any) => {
			if (visible) {
				setTimeout(() => searchInput.select());
			}
		},
		render: (text: any) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text.toString()}
				/>
			) : (
				text
			),
	});


	function doQueryData() {
		setLoadingData(true);
		setLastEvaluatedKey("");
		getMutationData().then(response => {
			parseResponse(response, false);
		}).catch(err => {
			setLoadingData(false);

		})
	}

	function handleMoreQuery() {
		setMoreDataLoading(true);
		getMoreMutationData().then(response => {
			parseResponse(response, true);
		}).catch(err => {
			console.log(err);
		}).finally(() => {
			setMoreDataLoading(false);
		})
	}

	function parseItems(items: any) {
		var newItems = [];
		for (var i in items) {
			var item = items[i];
			item["requestTimestampString"] = moment.utc(item["requestTimestamp"]).local().format('DD/MM/YY HH:mm:ss.SSSZ');

			if (!item.hasOwnProperty("user")) {
				item["user"] = "";
			}
			newItems.push(item);
		}

		return newItems;
	}

	function parseResponse(response: any, appendData: boolean) {
		setLoadingData(false);
		var payload = JSON.parse(response.responseBody.Payload);
		var body = JSON.parse(payload.body);
		var items = body.items;
		let newItems = parseItems(items);

		if (body.hasOwnProperty("LastEvaluatedKey")) {
			setLastEvaluatedKey(body.LastEvaluatedKey);
		} else {
			setLastEvaluatedKey("");
		}
		if (appendData) {
			// @ts-ignore
			setInboxData(inboxData.concat(newItems));
		} else {
			// @ts-ignore
			setInboxData(newItems);
		}
		if (body.hasOwnProperty("resellerUnclaimedCommmission")) {
			setCurrentBalance(toCurrency(body.resellerUnclaimedCommmission));
		}
	}

	function toCurrency(numberString: any) {
		let number = parseFloat(numberString);
		return number.toLocaleString('ID');
	}


	function getMutationDataParams() {
		let params = {
			headers: {
				'X-UID': getUidFromCookie()
			},
			queryStringParameters: {}
		};

		if (selectedUplineId !== "") {
			// @ts-ignore
			params["queryStringParameters"]["uplineId"] = selectedUplineId;
			// @ts-ignore
			params["queryStringParameters"]["endTimestamp"] = filterEndTimestamp.endOf('day').valueOf();
			// @ts-ignore
			params["queryStringParameters"]["startTimestamp"] = filterStartTimestamp.startOf('day').valueOf();
		}

		if (transactionIdInput !== "" ) {
			// @ts-ignore
			params["queryStringParameters"]["transactionId"] = transactionIdInput;
		}
		return params;
	}

	function getMutationData() {
		return API.get('pejuangweb', '/api/mutationCommission/' , getMutationDataParams());
	}

	function getMoreMutationData() {
		let params = getMutationDataParams();
		if (lastEvaluatedKey != "") {
			// @ts-ignore
			params.queryStringParameters["lastEvaluatedKey"] = JSON.stringify(lastEvaluatedKey);
		}
		return API.get('pejuangweb', '/api/mutationCommmission/', params);
	}

	function handleDateChange(dates: any) {
		onFilterStartTimestampChange(dates[0]);
		onFilterEndTimestampChange(dates[1]);
	}

	function downloadExcel() {
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const fileExtension = '.xlsx';

		const ws = XLSX.utils.json_to_sheet(inboxData);
		const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
		const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
		const data = new Blob([excelBuffer], {type: fileType});
		const date = filterStartTimestamp + "_" + filterEndTimestamp;
		FileSaver.saveAs(data, "MutationBalance_" + date + fileExtension);
	}

	const modalColumns = [

		{
			title: 'Timestamp',
			dataIndex: 'requestTimestampString',
			sorter: (a: { requestTimestamp: number; }, b: { requestTimestamp: number; }) => a.requestTimestamp - b.requestTimestamp,
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			defaultSortOrder: 'descend',
			...getColumnSearchProps('requestTimestampString'),
		},
		{
			title: 'ProductID',
			dataIndex: 'productId',
			sorter: (a: { productId: string; }, b: { productId: string; }) => a.productId.localeCompare(b.productId),
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			defaultSortOrder: 'descend',
			...getColumnSearchProps('productId'),

		},
		{
			title: 'Balance',
			dataIndex: 'balance',
			sorter: (a: { balance: number; }, b: { balance: number; }) => a.balance - b.balance,
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			defaultSortOrder: 'descend',
			...getColumnSearchProps('balance'),
			render: (text: any, record: any) => (
				<Typography.Text>
					{toCurrency(text)}
				</Typography.Text>
			)

		},
		{
			title: 'Reseller Balance',
			dataIndex: 'currentBalance',
			sorter: (a: { currentBalance: number; }, b: { currentBalance: number; }) => a.currentBalance - b.currentBalance,
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			defaultSortOrder: 'descend',
			...getColumnSearchProps('currentBalance'),
			render: (text: any, record: any) => (
				<Typography.Text>
					{toCurrency(text)}
				</Typography.Text>
			)

		},
		{
			title: 'TrxID',
			dataIndex: 'transactionId',
			sorter: (a: { resellerBalance: number; }, b: { resellerBalance: number; }) => a.resellerBalance - b.resellerBalance,
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			defaultSortOrder: 'descend',
			...getColumnSearchProps('transactionId'),

		},
		{
			title: 'Type',
			dataIndex: 'type',
			sorter: (a: { type: string; }, b: { type: string; }) => a.type.localeCompare(b.type),
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			defaultSortOrder: 'descend',
			...getColumnSearchProps('type'),

		},
		{
			title: 'User',
			dataIndex: 'user',
			sorter: (a: { user: string; }, b: { user: string; }) => a.user.localeCompare(b.user),
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			defaultSortOrder: 'descend',
			...getColumnSearchProps('user'),

		}
	];

	function onShowUplineModal() {
		setShowUplineModal(true);
		setUplineModalKey((Math.random() * 1000).toString());
	}

	function onSelectUpline(uplineData : any) {
		setSelectedUplineId(uplineData.id);
		setUplineValue(uplineData.id + " - " + uplineData.name);
	}

	return (

		<div>
			<Row>
				<Col span={24}>
					<Card bordered={false} className="header-background">
						<Title level={4}>Mutation Commission</Title>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col span={12} style={{textAlign: "left"}}>

					<Card bordered={true} style={{height : 250, borderRadius: 0}}>
						<Row>
							<Title level={4}>Search by Date</Title>
						</Row>
						<Row>
							<Col span={24} style={{textAlign: "left"}}>
								<Input style={{width : 400}} disabled value={uplineValue}/>
								<Button onClick={() => onShowUplineModal()}>Select Upline</Button>
							</Col>
						</Row>
						<br/>
						<Row>
							<Col span={24} style={{textAlign: "left"}}>
								<RangePicker size="large"
								             defaultValue={[moment(), moment()]}
								             onChange={(dates, dateString) => handleDateChange(dates)}/>

								<Button type="primary" style={{marginLeft: "10px"}} onClick={() => doQueryData()}
								        loading={queryLoading}>Query Data</Button>

							</Col>
						</Row>

						<Row style={{marginTop : "20px"}}>
							<Statistic title="Available Commission" value={currentBalance} precision={3} valueStyle={{ color: '#3f8600' }}/>
						</Row>
					</Card>
				</Col>
				<Col span={12} style={{textAlign: "left"}}>
					<Card bordered={true} style={{height : 250, borderRadius: 0}}>
						<Title level={4}>Search by Transaction ID</Title>
						<Col span={24} style={{textAlign: "left"}}>
							<Input placeholder="TransactionID"
							       onChange={(e) => onInputTransactionId(e.target.value)}
							       style={{width: 200, marginRight: "10px"}}/>
							<Button type="primary" style={{marginLeft: "10px"}} onClick={() => doQueryData()}
							        loading={queryLoading}>Query Transaction ID</Button>
						</Col>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col span={24}>
					<Card bordered={false}>

						{inboxData.length !== 0 &&
						<Button type="default" style={{marginTop: "10px", float : "right", zIndex: 9999}}
						        onClick={() => downloadExcel()} loading={queryLoading}>Download Excel</Button>
						}
						{lastEvaluatedKey !== "" &&
						<Button type="primary" onClick={() => handleMoreQuery()}
						        loading={moreDataLoading}
						        style={{float: "right", marginTop: "10px", zIndex: 9999, marginRight : "10px"}}
						>View More</Button>
						}

						<Table
							size="small"
							loading={loadingData}
							bordered
							scroll={{x: true}}
							dataSource={inboxData}
							// @ts-ignore
							columns={modalColumns}
						/>
					</Card>
					<ListUplineModal
						key={uplineModalKey}
						isVisible={showUplineModal}
						onSelectData={(uplineData : any) => onSelectUpline(uplineData)}/>
				</Col>
			</Row>
		</div>
	);
};


export default MutationCommission;
