import React, {useEffect, useState} from "react";
import {Alert, Descriptions, Input, Modal} from "antd";
import {API, Auth} from "aws-amplify";
import {getUidFromCookie} from "../../helpers/getUserId";

const {TextArea} = Input;

type ApproveTransactionModalProps = {
    dataSource: any
    visible: any
}

const ApproveTransactionModal: React.FC<ApproveTransactionModalProps> = (props) => {
    const [queryLoading, setQueryLoading] = useState(false);
    const [actionRecord, setActionRecord] = useState(props.dataSource);
    const [serialNumber, setSerialNumber] = useState('');
    const [declineTableVisible, setDeclineTableVisible] = useState(props.visible);
    const [errorMessage, setErrorMessage] = useState('');
    const [hideErrorClassName, setHideErrorClassName] = useState('hide');
    const [userId, setUserId] = useState('');
    const [transactionId, setTransactionId] = useState(props.dataSource.hasOwnProperty("transactionId") ? props.dataSource.transactionId : '');
    const [phoneNumber, setPhoneNumber] = useState(props.dataSource.hasOwnProperty("phoneNumber") ? props.dataSource.phoneNumber : '');
    const [status, setStatus] = useState(props.dataSource.hasOwnProperty("status") ? props.dataSource.status : '');

    useEffect(() => {
    }, []);


    function handleApproveTransaction() {
        if (serialNumber === "") {
            setHideErrorClassName('show');
            setErrorMessage("Enter serial number");
            return;
        }
        setHideErrorClassName('hide');

        var message = "";
        if (actionRecord.hasOwnProperty("message")) {
            message = actionRecord.message;
        }

        setQueryLoading(true);
        updateTransactionManual(actionRecord, "SUCCESS", message, serialNumber)
            .then(data => {
                var payload = (JSON.parse(data.responseBody.Payload));
                if (payload.success) {
                    setDeclineTableVisible(false);
                } else {
                    setErrorMessage(payload.body);
                    setHideErrorClassName('show');
                }
            }).catch(err => {
            setErrorMessage("Something wrong with the system. Please contact the administrator.");
            setHideErrorClassName('show');

        }).finally(() => {
            setQueryLoading(false);
        })
    }

    async function updateTransactionManual(record: any, status: any, message: any, serialNumber: any) {
        var id = await Auth.currentUserInfo();

        let myInit = {
            headers: {
                'X-UID': getUidFromCookie()
            },
            queryStringParameters: {
                transactionId: record.id,
                serialNumber: serialNumber,
                message: message,
                status: status,
                userId: id.username
            }
        };

        return API.put('pejuangweb', '/api/transactionManual/' + record.id, myInit);
    }

    function handleCancel() {
        setDeclineTableVisible(false);
    }


    // @ts-ignore
    return (
        <div>

            <Modal
                title="Approve Transaction"
                visible={declineTableVisible}
                confirmLoading={queryLoading}
                onOk={() => handleApproveTransaction()}
                onCancel={() => handleCancel()}>
                <div>
                    <Alert
                        message={errorMessage}
                        type="error"
                        className={hideErrorClassName}
                    />
                    <br/>
                    <Descriptions size="small" layout="vertical" bordered>
                        <Descriptions.Item label="TransactionID">{transactionId}</Descriptions.Item>
                        <Descriptions.Item label="PhoneNumber">{phoneNumber}</Descriptions.Item>
                        <Descriptions.Item label="Status">{status}</Descriptions.Item>
                    </Descriptions>
                    <br/>
                    <Input onChange={(e) => setSerialNumber(e.target.value)} placeholder="Serial Number"/>
                </div>
            </Modal>
        </div>
    );
};


export default ApproveTransactionModal;
