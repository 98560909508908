import React, {useEffect, useState} from "react";
import { API } from "aws-amplify";
import {
	Button,
	Card,
	Col,
	DatePicker,
	Divider, Input,
	Layout,
	Popconfirm,
	Row,
	Select,
	Table,
	Tabs,
	Tag,
	Typography
} from "antd";

import {
	SearchOutlined,
	ExclamationCircleTwoTone,
	CheckCircleTwoTone,
} from '@ant-design/icons';

import Highlighter from "react-highlight-words";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {getUidFromCookie} from "../../helpers/getUserId";
var moment = require('moment');
const { Title } = Typography;
const {RangePicker} = DatePicker;
const {Option} = Select;

const { Content, Footer } = Layout;
const { TabPane } = Tabs;

type props = {}

const TransferSaldo: React.FC<props> = (props) => {
	const [loadingData, setLoadingData] = useState(false);
	const [inboxData, setInboxData] = useState([]);
	const [searchInput, setSearchInput] = useState<any | null>(null);
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchColumn] = useState('');
	const [filterStartTimestamp, onFilterStartTimestampChange] = useState(moment());
	const [filterEndTimestamp, onFilterEndTimestampChange] = useState(moment());
	const [queryLoading, setQueryLoading] = useState(false);
	const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
	const [moreDataLoading, setMoreDataLoading] = useState(false);


	useEffect(() => {
	}, []);


	function handleSearch(selectedKeys: any, confirm: any, dataIndex: any) {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchColumn(dataIndex);
	}

	function handleReset(clearFilters: any) {
		clearFilters();
		setSearchText('');
	}


	const getColumnSearchProps = (dataIndex: any) => ({
		// @ts-ignore
		// @ts-ignore
		filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
			<div style={{padding: 8}}>
				<Input
					ref={(node: any) => {
						setSearchInput(node);
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{width: 188, marginBottom: 8, display: 'block'}}
				/>
				<Button
					type="primary"
					onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
					icon="search"
					size="small"
					style={{width: 90, marginRight: 8}}
				>
					Search
				</Button>
				<Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
					Reset
				</Button>
			</div>
		),
		filterIcon: (filtered: any) => (
			<SearchOutlined />
		),
		onFilter: (value: any, record: any) => {
			if (record[dataIndex] === undefined) {
				return false
			}
			return record[dataIndex]
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase())
		},
		onFilterDropdownVisibleChange: (visible: any) => {
			if (visible) {
				setTimeout(() => searchInput.select());
			}
		},
		render: (text: any) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text.toString()}
				/>
			) : (
				text
			),
	});


	function doQueryData() {
		setLoadingData(true);
		setLastEvaluatedKey("");
		getMutationData().then(response => {
			parseResponse(response, false);
		}).catch(err => {
			setLoadingData(false);

		})
	}

	function handleMoreQuery() {
		setMoreDataLoading(true);
		getMoreMutationData().then(response => {
			parseResponse(response, true);
		}).catch(err => {
			console.log(err);
		}).finally(() => {
			setMoreDataLoading(false);
		})
	}

	function parseItems(items: any) {
		var newItems = [];
		for (var i in items) {
			var item = items[i];
			let notes = JSON.parse(item.notes);

			if (notes.hasOwnProperty("from")) {
				item["uplineId"] = notes.from.transferUserId;
				item["uplineName"] = notes.from.transferName;

			} else {
				item["uplineId"] = "";
				item["uplineName"] = "";
			}

			if (notes.hasOwnProperty("to")) {
				item["downlineId"] = notes.to.transferUserId;
				item["downlineName"] = notes.to.transferName;

			} else {
				item["downlineId"] = "";
				item["downlineName"] = "";
			}
			// console.log(notes);

			if (notes.hasOwnProperty("isDownline")) {
				item["isDownline"] = notes.isDownline;
			} else {
				item["isDownline"] = false;
			}
			newItems.push(item);
		}

		return newItems;
	}

	function parseResponse(response: any, appendData: boolean) {
		setLoadingData(false);
		var payload = JSON.parse(response.responseBody.Payload);
		var body = JSON.parse(payload.body);
		var items = body.items;
		let newItems = parseItems(items);
		// console.log(body);

		if (body.hasOwnProperty("LastEvaluatedKey")) {
			setLastEvaluatedKey(body.LastEvaluatedKey);
		} else {
			setLastEvaluatedKey("");
		}
		if (appendData) {
			// @ts-ignore
			setInboxData(inboxData.concat(newItems));
		} else {
			// @ts-ignore
			setInboxData(newItems);
		}
	}

	function toCurrency(numberString: any) {
		let number = parseFloat(numberString);
		return number.toLocaleString('ID');
	}


	function getMutationDataParams() {
		let params = {
			headers: {
				'X-UID': getUidFromCookie()
			},
			queryStringParameters: {}
		};

		// @ts-ignore
		params["queryStringParameters"]["endTimestamp"] = filterEndTimestamp.endOf('day').valueOf();
		// @ts-ignore
		params["queryStringParameters"]["startTimestamp"] = filterStartTimestamp.startOf('day').valueOf();

		return params;
	}

	function getMutationData() {
		return API.get('pejuangweb', '/api/mutationTransferBalance/' , getMutationDataParams());
	}

	function getMoreMutationData() {
		let params = getMutationDataParams();
		if (lastEvaluatedKey != "") {
			// @ts-ignore
			params.queryStringParameters["lastEvaluatedKey"] = JSON.stringify(lastEvaluatedKey);
		}
		return API.get('pejuangweb', '/api/mutationTransferBalance/', params);
	}

	function handleDateChange(dates: any) {
		onFilterStartTimestampChange(dates[0]);
		onFilterEndTimestampChange(dates[1]);
	}

	function downloadExcel() {
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const fileExtension = '.xlsx';

		const ws = XLSX.utils.json_to_sheet(inboxData);
		const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
		const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
		const data = new Blob([excelBuffer], {type: fileType});
		const date = filterStartTimestamp + "_" + filterEndTimestamp;
		FileSaver.saveAs(data, "MutationBalance_" + date + fileExtension);
	}

	const modalColumns = [
		{
			title: 'ID',
			dataIndex: 'id',
			sorter: (a: { id: string; }, b: { id: string; }) => a.id.localeCompare(b.id),
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			...getColumnSearchProps('id'),

		},
		{
			title: 'Request Timestamp',
			dataIndex: 'requestTimestamp',
			sorter: (a: { requestTimestamp: number; }, b: { requestTimestamp: number; }) => a.requestTimestamp - b.requestTimestamp,
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			defaultSortOrder: 'descend',
			...getColumnSearchProps('requestTimestamp'),
			render: (text: any, record: any) => (
				<Typography.Text>
					{moment.utc(text).local().format('DD/MM/YY HH:mm:ss.SSSZ')}
				</Typography.Text>
			)

		},
		{
			title: 'Update Timestamp',
			dataIndex: 'updatedTimestamp',
			sorter: (a: { updatedTimestamp: number; }, b: { updatedTimestamp: number; }) => a.updatedTimestamp - b.updatedTimestamp,
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			...getColumnSearchProps('updatedTimestamp'),
			render: (text: any, record: any) => (
				<Typography.Text>
					{moment.utc(text).local().format('DD/MM/YY HH:mm:ss.SSSZ')}
				</Typography.Text>
			)
		},
		{
			title: 'Sender ID',
			dataIndex: 'uplineId',
			sorter: (a: { uplineId: string; }, b: { uplineId: string; }) => a.uplineId.localeCompare(b.uplineId),
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			...getColumnSearchProps('uplineId'),

		},
		{
			title: 'Sender Name',
			dataIndex: 'uplineName',
			sorter: (a: { uplineName: string; }, b: { uplineName: string; }) => a.uplineName.localeCompare(b.uplineName),
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			...getColumnSearchProps('uplineName'),

		},
		{
			title: 'Receiver ID',
			dataIndex: 'downlineId',
			sorter: (a: { downlineId: string; }, b: { downlineId: string; }) => a.downlineId.localeCompare(b.downlineId),
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			...getColumnSearchProps('downlineId'),

		},
		{
			title: 'Receiver Name',
			dataIndex: 'downlineName',
			sorter: (a: { downlineName: string; }, b: { downlineName: string; }) => a.downlineName.localeCompare(b.downlineName),
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			...getColumnSearchProps('downlineName'),

		},
		{
			title: 'Nominal',
			dataIndex: 'balance',
			sorter: (a: { balance: number; }, b: { balance: number; }) => a.balance - b.balance,
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			...getColumnSearchProps('balance'),
			render: (text: any, record: any) => (
				<Typography.Text>
					{toCurrency(text)}
				</Typography.Text>
			)

		},
		{
			title: 'isDownline',
			key: 'isDownline',
			dataIndex: 'isDownline',
			render: (isActive: any, record: any) => (
				<span>
						<Tag color={getTagColor(record)}>
							{getTagMessage(record)}
						</Tag>
				  	</span>
			)
		},
		{
			title: 'Channel',
			dataIndex: 'channel',
			sorter: (a: { channel: string; }, b: { channel: string; }) => a.channel.localeCompare(b.channel),
			sortDirections: ['descend' as 'descend', 'ascend' as 'ascend'],
			...getColumnSearchProps('channel'),
		}
	];

	function getTagColor(record: any){
		if (record.isDownline) {
			return 'green';
		} else {
			return 'red';
		}
	}

	function getTagMessage(record: any){
		if (record.isDownline) {
			return <CheckCircleTwoTone twoToneColor="#52c41a"/>;
		} else {
			return <ExclamationCircleTwoTone twoToneColor="#eb2f96"/>;
		}
	}


	return (
		<div>

			<Row>
				<Col span={24} className="content-layout">

					<Card bordered={false} style={{borderRadius: 0}}>
						<br/>
						<Row>
							<Col span={24} style={{textAlign: "left"}}>
								<RangePicker size="large"
								             defaultValue={[moment(), moment()]}
								             onChange={(dates, dateString) => handleDateChange(dates)}/>

								<Button type="primary" style={{marginLeft: "10px"}} onClick={() => doQueryData()}
								        loading={queryLoading}>Query Data</Button>

							</Col>
						</Row>
						<br/>
						<Row>
							<Col span={24}>
								{inboxData.length !== 0 &&
								<Button type="default" style={{marginTop: "10px", float : "right", zIndex: 9999}}
								        onClick={() => downloadExcel()} loading={queryLoading}>Download Data</Button>
								}
								{lastEvaluatedKey !== "" &&
								<Button type="primary" onClick={() => handleMoreQuery()}
								        loading={moreDataLoading}
								        style={{float: "right", marginTop: "10px", zIndex: 9999, marginRight : "10px"}}
								>View More</Button>
								}

								<Table
									size="small"
									loading={loadingData}
									bordered
									scroll={{x: true}}
									dataSource={inboxData}
									// @ts-ignore
									columns={modalColumns}
								/>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</div>
	);
};


export default TransferSaldo;
