import { Menu, Layout, Row, Col, Typography, Avatar, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import React, { Suspense, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import {
  AlertOutlined,
  ApartmentOutlined,
  CreditCardOutlined,
  FileSearchOutlined,
  AreaChartOutlined,
  AuditOutlined,
  PlusSquareOutlined,
  UserAddOutlined,
  UsergroupDeleteOutlined,
  UsergroupAddOutlined,
  MoneyCollectOutlined,
  HistoryOutlined,
  PayCircleOutlined,
  AppstoreAddOutlined,
  BankOutlined,
  AntDesignOutlined,
  WalletOutlined,
  GiftOutlined,
  DollarOutlined,
  FileImageOutlined,
  CrownOutlined,
  IdcardOutlined,
  UserOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import Priceplan from "../priceplan/Priceplan";
import PriceplanDetails from "../priceplan/PriceplanDetails";
import TransactionHistory from "../transaction/TransactionHistory";
import { API, Auth } from "aws-amplify";
import RefundTransaction from "../transaction/RefundTransaction";
import CreateReseller from "../reseller/CreateReseller";
import Resellers from "../reseller/Resellers";
import MutationCommission from "../commission/MutationCommission";
import ClaimCommission from "../commission/ClaimCommission";
import AddBalance from "../Deposit/AddBalance";
import TopupDeposit from "../Deposit/TopupDeposit";
import Disbursement from "../bankTransfer/Disbursement";
import Maintenance from "../maintenance/Maintenance";
import MutationWallet from "../Deposit/MutationWallet";
import BannersList from "../promo/BannersList";
import CashbackList from "../promo/CashbackList";
import BannersCreate from "../promo/BannersCreate";
import CashbackCreate from "../promo/CashbackCreate";
import VerifiedResellers from "../reseller/VerifiedReseller";
import UsersList from "../userAccess/ListUsers";
import UserRole from "../userAccess/ListRole";
import CreateRole from "../userAccess/CreateRole";
import CreateUser from "../userAccess/CreateUser";

const Products = React.lazy(() => import("./../products/Products"));
const CreateProduct = React.lazy(() => import("./../products/CreateProduct"));

const { SubMenu } = Menu;
const { Content, Footer, Sider } = Layout;
const { Title } = Typography;

type Props = {};

const Navigation: React.FC<Props> = (props: any) => {
  const cookies = new Cookies();

  const [collapsed, setCollapsed] = useState(false);
  const [hideLogoClassName, setHideLogoClassName] = useState("show");
  const [permissions, setPermissions] = useState([]);
  const [username, setUserName] = useState("Agent");
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    fetchUsername();
    fetchUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchUsername() {
    var id = await Auth.currentUserInfo();
    if (id !== undefined) {
      setUserName(id.username);
    }
  }

  async function fetchUserId() {
    setLoadingData(true);
    await Auth.currentSession()
      .then((res: any) => {
        cookies.set("userId", res.accessToken.payload.sub);
        doQueryUserAccess(res.accessToken.payload.sub);
      })
      .catch((err: any) => {
        console.error("get userId error:", err);
        setLoadingData(false);
      });
  }

  function onCollapse(collapsed: boolean) {
    setCollapsed(collapsed);

    if (collapsed) {
      setHideLogoClassName("hide");
    } else {
      setHideLogoClassName("show");
    }
  }

  function doQueryUserAccess(data: any) {
    queryAccess(data)
      .then((res) => {
        const payload = JSON.parse(res.responseBody.Payload);
        if (payload.success) {
          const body = JSON.parse(payload.body);
          setPermissions(body.permissions);
          setLoadingData(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoadingData(false);
      });
  }

  function queryAccess(data: any) {
    const init = { headers: { "X-UID": `${data}` } };
    return API.get("pejuangweb", "/api/my-permission", init);
  }

  function checkPermission(permissionId: any) {
    // @ts-ignore
    return permissions.indexOf(permissionId) > -1;
  }
  //
  async function signOut() {
    try {
      await Auth.signOut();
      cookies.remove("userId");
    } catch (error) {
      console.error("error signing out: ", error);
    }
  }
  // if (loadingData) {
  //   return (
  //
  //   );
  // }

  return loadingData ? (
    <div className="fullpage-loading">
      <LoadingOutlined style={{ fontSize: 36 }} spin />
    </div>
  ) : (
    <Router>
      <div>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(collapsed) => onCollapse(collapsed)}
            style={{
              textAlign: "left",
            }}
          >
            <img
              style={{
                height: "80px",
                float: "left",
                marginTop: 20,
                marginLeft: 50,
                marginRight: 8,
                marginBottom: 40,
              }}
              className={hideLogoClassName}
              src={"/logo.png"}
              alt="start"
            />
            <Menu theme="light" defaultSelectedKeys={["1"]} mode="inline">
              {checkPermission("MENU_LIST_TRANSACTION") ||
              checkPermission("MENU_REFUND") ? (
                <SubMenu
                  key="sub1"
                  icon={<FileSearchOutlined />}
                  title="Transactions"
                >
                  {
                    // @ts-ignore
                    checkPermission("MENU_LIST_TRANSACTION") && (
                      <Menu.Item
                        key="transactionHistory"
                        icon={<AreaChartOutlined />}
                      >
                        <Link to="/transactions/live">Live Transactions</Link>
                      </Menu.Item>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_REFUND") && (
                      <Menu.Item
                        key="refundTransaction"
                        icon={<AlertOutlined />}
                      >
                        <Link to="/transactions/refund">Refund</Link>
                      </Menu.Item>
                    )
                  }
                </SubMenu>
              ) : (
                ""
              )}
              {checkPermission("MENU_LIST_RESELLER") ||
              checkPermission("MENU_CREATE_RESELLER") ||
              checkPermission("MENU_UPGRADE_REQUEST") ? (
                <SubMenu
                  key="sub2"
                  icon={<UsergroupDeleteOutlined />}
                  title="Reseller"
                >
                  {
                    // @ts-ignore
                    checkPermission("MENU_LIST_RESELLER") && (
                      <Menu.Item key="reseller" icon={<UsergroupAddOutlined />}>
                        <Link to="/resellers">List Resellers</Link>
                      </Menu.Item>
                    )
                  }

                  {
                    // @ts-ignore
                    checkPermission("MENU_CREATE_RESELLER") && (
                      <Menu.Item
                        key="createReseller"
                        icon={<UserAddOutlined />}
                      >
                        <Link to="/reseller/create">Create Reseller</Link>
                      </Menu.Item>
                    )
                  }

                  {
                    // @ts-ignore
                    checkPermission("MENU_UPGRADE_REQUEST") && (
                      <Menu.Item
                        key="verifiedReseller"
                        icon={<CrownOutlined />}
                      >
                        <Link to="/reseller/verified">Verified Reseller</Link>
                      </Menu.Item>
                    )
                  }
                </SubMenu>
              ) : (
                ""
              )}
              {checkPermission("MENU_LIST_PRODUCT") ||
              checkPermission("MENU_CREATE_PRODUCT") ? (
                <SubMenu
                  key="sub3"
                  icon={<ApartmentOutlined />}
                  title="Products"
                >
                  {
                    // @ts-ignore
                    checkPermission("MENU_LIST_PRODUCT") && (
                      <Menu.Item key="products" icon={<AuditOutlined />}>
                        <Link to="/products">List Products</Link>
                      </Menu.Item>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_CREATE_PRODUCT") && (
                      <Menu.Item
                        key="createProduct"
                        icon={<PlusSquareOutlined />}
                      >
                        <Link to="/products/create">Create Product</Link>
                      </Menu.Item>
                    )
                  }
                </SubMenu>
              ) : (
                ""
              )}
              {checkPermission("MENU_PRICE_PLAN") && (
                <SubMenu
                  key="sub4"
                  icon={<CreditCardOutlined />}
                  title="Priceplan"
                >
                  <Menu.Item key="priceplan" icon={<AuditOutlined />}>
                    <Link to="/priceplan">List Priceplan</Link>
                  </Menu.Item>
                </SubMenu>
              )}

              {checkPermission("MENU_MANAGE_BANNER") ||
              checkPermission("MENU_MANAGE_PROMO") ? (
                <SubMenu
                  key="promoSubMenu"
                  icon={<GiftOutlined />}
                  title="Promo"
                >
                  {
                    // @ts-ignore
                    checkPermission("MENU_MANAGE_BANNER") && (
                      <Menu.Item key="banner" icon={<FileImageOutlined />}>
                        <Link to="/promo/banner">Banner</Link>
                      </Menu.Item>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_MANAGE_PROMO") && (
                      <Menu.Item key="cashback" icon={<DollarOutlined />}>
                        <Link to="/promo/cashback">Cashback</Link>
                      </Menu.Item>
                    )
                  }
                </SubMenu>
              ) : (
                ""
              )}

              {checkPermission("MENU_MUTATION") ||
              checkPermission("MENU_CLAIM") ? (
                <SubMenu
                  key="sub5"
                  icon={<MoneyCollectOutlined />}
                  title="Commission"
                >
                  {
                    // @ts-ignore
                    checkPermission("MENU_MUTATION") && (
                      <Menu.Item key="commission" icon={<HistoryOutlined />}>
                        <Link to="/commission/mutation">Mutation</Link>
                      </Menu.Item>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_CLAIM") && (
                      <Menu.Item
                        key="claimCommission"
                        icon={<PayCircleOutlined />}
                      >
                        <Link to="/commission/claim">Claim</Link>
                      </Menu.Item>
                    )
                  }
                </SubMenu>
              ) : (
                ""
              )}

              {checkPermission("MENU_ADD_BALANCE") ||
              checkPermission("MENU_TOPUP_DEPOSIT") ||
              checkPermission("MENU_MUTATION_WALLET") ? (
                <SubMenu
                  key="depositSubMenu"
                  icon={<BankOutlined />}
                  title="Deposit"
                >
                  {
                    // @ts-ignore
                    checkPermission("MENU_ADD_BALANCE") && (
                      <Menu.Item
                        key="depositAddBalance"
                        icon={<AppstoreAddOutlined />}
                      >
                        <Link to="/deposit/balance/add">Add Balance</Link>
                      </Menu.Item>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_TOPUP_DEPOSIT") && (
                      <Menu.Item
                        key="depositTopUp"
                        icon={<AntDesignOutlined />}
                      >
                        <Link to="/deposit/topup">Topup Deposit</Link>
                      </Menu.Item>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_MUTATION_WALLET") && (
                      <Menu.Item key="mutationWallet" icon={<WalletOutlined />}>
                        <Link to="/deposit/MutationWallet">
                          Mutation Wallet
                        </Link>
                      </Menu.Item>
                    )
                  }
                </SubMenu>
              ) : (
                ""
              )}

              {checkPermission("MENU_DISBURSEMENT") && (
                <SubMenu
                  key="bankTransferSubMenu"
                  icon={<BankOutlined />}
                  title="Bank Transfer"
                >
                  <Menu.Item key="transfer" icon={<AppstoreAddOutlined />}>
                    <Link to="/transfer/Disbursement">Disbursement</Link>
                  </Menu.Item>
                </SubMenu>
              )}

              {checkPermission("MENU_ROLE") ||
              checkPermission("MENU_ADMINUSER") ? (
                <SubMenu
                  key="userAccessSubMenu"
                  icon={<IdcardOutlined />}
                  title="User Access"
                >
                  {
                    // @ts-ignore
                    checkPermission("MENU_ROLE") && (
                      <Menu.Item key="user_role" icon={<UnlockOutlined />}>
                        <Link to="/user-role">Role</Link>
                      </Menu.Item>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_ADMINUSER") && (
                      <Menu.Item key="users_access" icon={<UserOutlined />}>
                        <Link to="/users-access">Users</Link>
                      </Menu.Item>
                    )
                  }
                </SubMenu>
              ) : (
                ""
              )}

              {checkPermission("MENU_MAINTENANCE") && (
                <SubMenu
                  key="maintenanceMenu"
                  icon={<BankOutlined />}
                  title="Maintenance"
                >
                  <Menu.Item key="transfer" icon={<AppstoreAddOutlined />}>
                    <Link to="/maintenance/config">Maintenance Config</Link>
                  </Menu.Item>
                </SubMenu>
              )}
            </Menu>
          </Sider>
          <Layout>
            <Content style={{ margin: "0 16px" }}>
              <Row>
                <Col span={24} style={{ backgroundColor: "#FBFBFB" }}>
                  <div style={{ backgroundColor: "#FBFBFB", padding: "10px" }}>
                    <Title
                      level={3}
                      style={{
                        textAlign: "left",
                        marginBottom: 0,
                        verticalAlign: "middle",
                      }}
                    >
                      Dashboard
                      <Title
                        level={5}
                        style={{
                          color: "#002140",
                          float: "right",
                          display: "inline-block",
                        }}
                      >
                        Welcome, {username}
                        <Avatar
                          style={{
                            backgroundColor: "#CE0000",
                            verticalAlign: "middle",
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                          size="default"
                        >
                          {username.substring(0, 1).toUpperCase()}
                        </Avatar>
                        <Button
                          style={{
                            backgroundColor: "#CE0000",
                            color: "#ffffff",
                          }}
                          shape="round"
                          onClick={() => signOut()}
                        >
                          Logout
                        </Button>
                      </Title>
                    </Title>
                  </div>
                </Col>
              </Row>
              <div>
                <Switch>
                  {
                    // @ts-ignore
                    checkPermission("MENU_LIST_TRANSACTION") && (
                      <Route exact path="/">
                        <Suspense fallback={<div>Loading...</div>}>
                          <TransactionHistory />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_REFUND") && (
                      <Route exact path="/transactions/refund">
                        <Suspense fallback={<div>Loading...</div>}>
                          <RefundTransaction />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_LIST_TRANSACTION") && (
                      <Route exact path="/transactions/live">
                        <Suspense fallback={<div>Loading...</div>}>
                          <TransactionHistory />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_CREATE_PRODUCT") && (
                      <Route path="/products/create">
                        <Suspense fallback={<div>Loading...</div>}>
                          <CreateProduct />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_LIST_PRODUCT") && (
                      <Route exact path="/products">
                        <Suspense fallback={<div>Loading...</div>}>
                          <Products />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_PRICE_PLAN") && (
                      <Route exact path="/priceplan">
                        <Suspense fallback={<div>Loading...</div>}>
                          <Priceplan />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_PRICE_PLAN") && (
                      <Route exact path="/priceplan/:id">
                        <Suspense fallback={<div>Loading...</div>}>
                          <PriceplanDetails />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_CREATE_RESELLER") && (
                      <Route path="/reseller/create">
                        <Suspense fallback={<div>Loading...</div>}>
                          <CreateReseller />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_LIST_RESELLER") && (
                      <Route path="/resellers">
                        <Suspense fallback={<div>Loading...</div>}>
                          <Resellers />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_UPGRADE_REQUEST") && (
                      <Route path="/reseller/verified">
                        <Suspense fallback={<div>Loading...</div>}>
                          <VerifiedResellers />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_MUTATION") && (
                      <Route path="/commission/mutation">
                        <Suspense fallback={<div>Loading...</div>}>
                          <MutationCommission />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_CLAIM") && (
                      <Route path="/commission/claim">
                        <Suspense fallback={<div>Loading...</div>}>
                          <ClaimCommission />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_ADD_BALANCE") && (
                      <Route path="/deposit/balance/add">
                        <Suspense fallback={<div>Loading...</div>}>
                          <AddBalance />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_TOPUP_DEPOSIT") && (
                      <Route path="/deposit/topup">
                        <Suspense fallback={<div>Loading...</div>}>
                          <TopupDeposit />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_MUTATION_WALLET") && (
                      <Route path="/deposit/MutationWallet">
                        <Suspense fallback={<div>Loading...</div>}>
                          <MutationWallet />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_DISBURSEMENT") && (
                      <Route path="/transfer/Disbursement">
                        <Suspense fallback={<div>Loading...</div>}>
                          <Disbursement />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_MAINTENANCE") && (
                      <Route path="/maintenance/config">
                        <Suspense fallback={<div>Loading...</div>}>
                          <Maintenance />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_MANAGE_BANNER") && (
                      <Route path="/promo/banner">
                        <Suspense fallback={<div>Loading...</div>}>
                          <BannersList />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_MANAGE_BANNER") && (
                      <Route path="/promo/create-banner">
                        <Suspense fallback={<div>Loading...</div>}>
                          <BannersCreate />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_MANAGE_PROMO") && (
                      <Route path="/promo/cashback">
                        <Suspense fallback={<div>Loading...</div>}>
                          <CashbackList />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_MANAGE_PROMO") && (
                      <Route path="/promo/create-cashback">
                        <Suspense fallback={<div>Loading...</div>}>
                          <CashbackCreate />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_ADMINUSER") && (
                      <Route path="/users-access">
                        <Suspense fallback={<div>Loading...</div>}>
                          <UsersList />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_ADMINUSER") && (
                      <Route path="/create-users">
                        <Suspense fallback={<div>Loading...</div>}>
                          <CreateUser />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_ROLE") && (
                      <Route path="/user-role">
                        <Suspense fallback={<div>Loading...</div>}>
                          <UserRole />
                        </Suspense>
                      </Route>
                    )
                  }
                  {
                    // @ts-ignore
                    checkPermission("MENU_ROLE") && (
                      <Route path="/create-role">
                        <Suspense fallback={<div>Loading...</div>}>
                          <CreateRole />
                        </Suspense>
                      </Route>
                    )
                  }
                </Switch>
              </div>
              <Footer style={{ textAlign: "center" }}>Pejuang ©2020</Footer>
            </Content>
          </Layout>
        </Layout>
      </div>
    </Router>
  );
};

export default Navigation;
