import React, {useEffect, useState} from "react";
import {Alert, Input, Modal, Row, Select} from "antd";
import {API, Auth} from "aws-amplify";
import {getUidFromCookie} from "../../helpers/getUserId";

const {TextArea} = Input;
const {Option} = Select;

type DeclineTransactionModalProps = {
    dataSource: any
    visible: any
}

const DeclineTransactionModal: React.FC<DeclineTransactionModalProps> = (props) => {


    const [queryLoading, setQueryLoading] = useState(false);
    const [actionRecord, setActionRecord] = useState(props.dataSource);
    let defaultFailedMessage = "Transaksi ID " + actionRecord.id +
        " GAGAL. Nomor tujuan = " + actionRecord.phoneNumber + " . Kode Produk = " + actionRecord.productId;
    const [failedMessage, setFailedMessage] = useState(defaultFailedMessage);
    const [declineTableVisible, setDeclineTableVisible] = useState(props.visible);
    const [errorMessage, setErrorMessage] = useState('');
    const [hideErrorClassName, setHideErrorClassName] = useState('hide');
    const [userId, setUserId] = useState('');

    useEffect(() => {
    }, []);


    function handleDeclineTransaction() {
        if (failedMessage === "") {
            setHideErrorClassName('show');
            setErrorMessage("Enter error message");
            return;
        }
        setHideErrorClassName('hide');
        setQueryLoading(true);
        updateTransactionManual(actionRecord, "FAILED", failedMessage, "SN")
            .then(data => {
                var payload = (JSON.parse(data.responseBody.Payload));
                if (payload.success) {
                    setDeclineTableVisible(false);
                } else {
                    setErrorMessage(payload.body);
                    setHideErrorClassName('show');
                }
            }).catch(err => {
            setErrorMessage("Something wrong with the system. Please contact the administrator.");
            setHideErrorClassName('show');

        }).finally(() => {
            setQueryLoading(false);
        })
    }

    async function updateTransactionManual(record: any, status: any, message: any, serialNumber: any) {
        var id = await Auth.currentUserInfo();

        let myInit = {
            headers: {
                'X-UID': getUidFromCookie()
            },
            queryStringParameters: {
                transactionId: record.id,
                serialNumber: serialNumber,
                message: message,
                status: status,
                userId: id.username
            }
        };

        return API.put('pejuangweb', '/api/transactionManual/' + record.id, myInit);
    }

    function handleCancel() {
        setDeclineTableVisible(false);
    }

    function handleOptionChange(e: any) {
        if (e === "WRONG_NUMBER") {
            let defaultFailedMessage = "Transaksi ID " + actionRecord.id + " GAGAL. Kode Produk = " +
                actionRecord.productId + " Nomor tujuan " + actionRecord.phoneNumber + " salah. ";
            setFailedMessage(defaultFailedMessage)
        } else {
            setFailedMessage(defaultFailedMessage)
        }
    }


    // @ts-ignore
    return (
        <div>

            <Modal
                title="Decline Transaction"
                visible={declineTableVisible}
                confirmLoading={queryLoading}
                onOk={() => handleDeclineTransaction()}
                onCancel={() => handleCancel()}>
                <div>
                    <Alert
                        message={errorMessage}
                        type="error"
                        className={hideErrorClassName}
                    />
                    <br/>
                    <Row>
                        <Select defaultValue="GENERAL_ERROR" style={{width: 300}}
                                onChange={(e: any) => handleOptionChange(e)}>
                            <Option value="GENERAL_ERROR">General Failure</Option>
                            <Option value="WRONG_NUMBER">Wrong Number</Option>
                        </Select>
                    </Row>
                    <br/>
                    <Row>
                        <TextArea rows={2} onChange={(e) => setFailedMessage(e.target.value)} value={failedMessage}/>
                    </Row>
                </div>
            </Modal>
        </div>
    );
};


export default DeclineTransactionModal;
