import React from 'react';
import './App.css';
import Navigation from "./component/common/Navigation";
import { withAuthenticator } from '@aws-amplify/ui-react'

function App() {
  return (
    <div className="App">
      <Navigation/>
    </div>
  );
}

export default withAuthenticator(App)

