import React, {useEffect, useState} from "react";
import { API } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Select, Modal, Layout, Popconfirm, Row, Table, Tag, Typography, DatePicker } from "antd";
import { SyncOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { CashbackModal } from "./CashbackModal";
import {getUidFromCookie} from "../../helpers/getUserId";

const moment = require('moment');

const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;


const PromoList: React.FC = () => {
  const history = useHistory();

  const [viewDetailsModal, setViewDetailsModal] = useState(false);
	const [viewId, setViewId] = useState('');
  const [modalKey, setModalKey] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const [dataCashback, setDataCashback] = useState([]);
  const [allProducts, setAllProducts] = useState<Array<any>>([]);
  const [dateStartForm, dateStartFormChange] = useState(0)
  const [dateEndForm, dateEndFormChange] = useState(0)
  const [productForm, productFormChange] =  useState('')

  useEffect(() => {
    getDataCashback();
    getProduct();
  }, []);

  function getDataCashback() {
    setLoadingData(true)
    getCashbackEndpoint().then(response => {
			const payload = JSON.parse(response.responseBody.Payload);
      if (payload.success) {
				const body = JSON.parse(payload.body);
				setDataCashback(body.data);
			}
			setLoadingData(false);
		});
  }

  function filterDataCashback() {
    setLoadingData(true)
    getCashbackFilterEndpoint().then(response => {
			const payload = JSON.parse(response.responseBody.Payload);
      if (payload.success) {
				const body = JSON.parse(payload.body);
				setDataCashback(body.data);
			}
			setLoadingData(false);
		});
  }

  function getProduct() {
    queryAllProducts().then(res => {
      const payload = JSON.parse(res.responseBody.Payload)
      if (payload.success) {
        const products = JSON.parse(payload.body);

        let newProviders = {};
        for (let i in products) {
          var product = products[i];
          if (!newProviders.hasOwnProperty(product.provider)) {
            // @ts-ignore
            newProviders[product.provider] = [product];
          } else {
            // @ts-ignore
            newProviders[product.provider].push(product);
          }
        }

        let listProviders = Object.keys(newProviders);
				setAllProducts(listProviders)
			}
    })
	}

  function deleteCashback(value: any) {
    deleteCashbackEndpoint(value).then(response => {
      Modal.success({
        content: 'Success',
        onOk : (args: any) => {
          window.location.reload();
        }
      });
    }).catch(err => alert("Error : " + err))
  }

  async function queryAllProducts() {
		return API.get('pejuangweb', '/api/product/', {
          headers: {
            'X-UID': getUidFromCookie()
          }
        });
  }

  async function getCashbackEndpoint() {
		let init = {
          headers: {
            'X-UID': getUidFromCookie()
          }
        };
		return await API.get('pejuangweb', '/api/promo/', init);
  }

  async function getCashbackFilterEndpoint() {
    let init = {
      queryStringParameters: {
        dateStart: dateStartForm > 0 ? dateStartForm : "",
        dateEnd: dateEndForm > 0 ? dateEndForm : "",
        productId: productForm,
      },
      headers: {
        'X-UID': getUidFromCookie()
      }
    };
		return await API.get('pejuangweb', '/api/promo/', init);
  }

  async function deleteCashbackEndpoint(params : any) {
		let init = {
          headers: {
            'X-UID': getUidFromCookie()
          }
        };
		return await API.del('pejuangweb', '/api/promo/' + params, init);
  }

  const columns = [
    {
      title: "Promo Code",
      dataIndex: "id",
      width: 200,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 400,
    },
    {
      title: "Max. Usage",
      dataIndex: "maxUsage",
      width: 80,
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionType",
      width: 100,
    },
    {
      title: "Promo Type",
      dataIndex: "promoType",
      width: 100,
    },
    {
      title: "Date Start",
      dataIndex: "dateStart",
      width: 100,
      render: (dateStart: any) => (
        <p>{moment(dateStart).format("DD-MM-YYYY")}</p>
      )
    },
    {
      title: "Date End",
      dataIndex: "dateEnd",
      width: 100,
      render: (dateEnd: any) => (
        <p>{moment(dateEnd).format("DD-MM-YYYY")}</p>
      )
    },
    {
      title: "Providers",
      dataIndex: "productIds",
      width: 200,
      render: (productIds: any) => {
        const text = productIds.toString();
        const replaceText = text.replace(/,/g, '\n');
        return replaceText
      }
    },
    {
      title: "Minimal Amount",
      dataIndex: "minimalAmount",
      width: 100,
      render: (minimalAmount: any) => (
        <p>{toCurrency(minimalAmount)}</p>
      )
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: 200,
      render: (amount: any) => (
        <div>
          <p><strong>Type: </strong> {amount.type}</p>
          <p><strong>Nominal: </strong> {toCurrency(amount.value)}</p>
          {amount.type === "percentage" && <p><strong>Maximal: </strong> {toCurrency(amount.maxValue)}</p>}
        </div>
      )
    },
    {
      title: "Actions",
      render: (text: any, record: any) => (
				renderActionButton(record)
			),
    }
  ]

  const toCurrency = (numberString: any) => {
		let number = parseFloat(numberString);
		return number.toLocaleString('ID');
	};

  function renderActionButton(record : any){
    return (
      <div className="flex-column">
        <Button style={{ marginBottom: 10 }} onClick={() => handleViewButton(record)}>View</Button>
        <Popconfirm title="Sure to delete data?" onConfirm={() => deleteCashback(record.id)}>
          <Button danger>Delete</Button>
        </Popconfirm>
      </div>
    )
  }

  function handleViewButton(record : any) {
		setModalKey((Math.random() * 1000).toString());
		setViewId(record.id);
		setViewDetailsModal(true);
	}

  function redirectToAddCashback() {
    history.push("/promo/create-cashback")
  }

  function sucessEdit() {
    getDataCashback()
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content className="pejuang-main-content">
        <Row>
					<Col span={24}>
						<Card bordered={false} className="header-background">
							<Title level={4}>Promo</Title>
						</Card>
					</Col>
        </Row>
        <Row>
          <Col span={24} className="content-layout">
            <Card>
              <Row>
                <Col span={24} style={{ textAlign: "right" }}>
                  <Button type="primary" icon={<PlusCircleOutlined />} style={{marginRight: "20px"}} onClick={() => redirectToAddCashback()}>
										Add Cashback
									</Button>
									<Button type="default" icon={<SyncOutlined />}>
										Refresh
									</Button>
                </Col>
                <Col span={24} style={{ textAlign: "left" }}>
                  <DatePicker placeholder="Start Date" style={{marginRight: "10px"}} onChange={(dates, dateString) => dateStartFormChange(moment(dates).valueOf())} />
                  <DatePicker placeholder="End Date" style={{marginRight: "10px"}} onChange={(dates, dateString) => dateEndFormChange(moment(dates).valueOf())}/>
                  <Select placeholder="Filter Products" showSearch style={{ marginRight: "10px", width: 400 }} onChange={(data : any) => productFormChange(data)}>
                    <Option value="">NONE</Option>
                    {
											allProducts.map((data: any, index: any) => {
												return <Option value={data} key={data} x>{data}</Option>})
										}
                  </Select>
                  <Button type="primary" onClick={() => filterDataCashback()}>Query</Button>
                </Col>
                <Col span={24} style={{marginTop: "20px"}}>
                  <Table
                    size={"small"}
                    loading={loadingData}
                    bordered
                    scroll={{x: true}}
                    dataSource={dataCashback}
                    // @ts-ignore
                    columns={columns}
                  />
                </Col>
              </Row>
              <Row></Row>
            </Card>
          </Col>
        </Row>
      </Content>
      <CashbackModal isVisible={viewDetailsModal} id={viewId} key={modalKey} onSaveComplete={() => sucessEdit()}/>
    </Layout>
  )
}

export default PromoList
