import {
  Form,
  Input,
  Button,
  Checkbox,
  Layout,
  Row,
  Col,
  Card,
  Typography,
  Select,
  Modal,
} from "antd";
import React, { useEffect, useState } from "react";
import { API } from "@aws-amplify/api";
import { ListUplineModal } from "../common/ListUplineModal";
import { getUidFromCookie } from "../../helpers/getUserId";

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

const CreateReseller: React.FC = () => {
  const [showUplineModal, setShowUplineModal] = useState(false);
  const [selectedUplineId, setSelectedUplineId] = useState("");
  const [uplineValue, setUplineValue] = useState("");
  const [uplineModalKey, setUplineModalKey] = useState("");
  const [loadingSubmit, setSubmitLoading] = useState(false);

  function onFinish(values: any) {
    console.log("Success:", values);
    values["uplineId"] = selectedUplineId;
    setSubmitLoading(true);
    create(values)
      .then((response) => {
        var payload = JSON.parse(response.responseBody.Payload);
        if (payload.success) {
          Modal.success({
            content: "Success",
            onOk: (args) => {
              window.location.reload();
            },
          });
        } else {
          const body = JSON.parse(payload.body);
          Modal.error({
            content: "Error : " + body,
          });
        }
        setSubmitLoading(false);
      })
      .catch((err) => {
        Modal.error({
          content: "Error : " + JSON.stringify(err),
          onOk: (args) => {
            window.location.reload();
          },
        });
        setSubmitLoading(false);
      });
  }

  function onFinishFailed(errorInfo: any) {
    console.log("Failed:", errorInfo);
  }

  useEffect(() => {
    doQueryData();
  }, []);

  function doQueryData() {}

  async function create(params: any) {
    var init = {
      body: params,
      headers: {
        "X-UID": getUidFromCookie(),
      },
    };
    return await API.post("pejuangweb", "/api/user/create", init);
  }

  function renderUserRole() {
    let res = [];
    res.push(<Option value="STANDARD">Standard</Option>);
    res.push(<Option value="SALES">Sales</Option>);
    return res;
  }

  function onSelectUpline(uplineData: any) {
    setSelectedUplineId(uplineData.id);
    setUplineValue(uplineData.id + " - " + uplineData.name);
  }

  function onShowUplineModal() {
    setShowUplineModal(true);
    setUplineModalKey((Math.random() * 1000).toString());
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content className="pejuang-main-content">
        <Row>
          <Col span={24}>
            <Card bordered={false} className="header-background">
              <Title level={4}>Create Reseller</Title>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="content-layout left-align">
            <Card>
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Nama"
                  name="name"
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Phone Number"
                  name="phoneNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Alamat"
                  name="address"
                  rules={[
                    { required: true, message: "Please input your address!" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Pin"
                  name="pin"
                  rules={[
                    { required: true, message: "Please input your pin!" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="User Role"
                  name="userRole"
                  rules={[
                    { required: true, message: "Please select your user role" },
                  ]}
                >
                  <Select allowClear>{renderUserRole()}</Select>
                </Form.Item>
                <Form.Item
                  {...tailLayout}
                  name="isActive"
                  valuePropName="checked"
                >
                  <Checkbox>Active ?</Checkbox>
                </Form.Item>
                <Form.Item
                  label="Upline"
                  name="uplineId"
                  rules={[
                    { required: false, message: "Please select your upline" },
                  ]}
                >
                  <Input style={{ width: 200 }} disabled value={uplineValue} />
                  <Button onClick={() => onShowUplineModal()}>
                    Select Upline
                  </Button>
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loadingSubmit}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <ListUplineModal
            key={uplineModalKey}
            isVisible={showUplineModal}
            onSelectData={(uplineData: any) => onSelectUpline(uplineData)}
          />
        </Row>
      </Content>
    </Layout>
  );
};

export default CreateReseller;
