import React, { useEffect, useState } from "react";
import { Col, Input, Row, Modal, Select } from "antd";
import { API } from "@aws-amplify/api";
import { LoadingOutlined } from "@ant-design/icons";
import { getUidFromCookie } from "../../helpers/getUserId";

const { Option } = Select;
type Props = {
  isVisible: any;
  id: any;
  onSaveComplete: any;
};

export const RoleModal: React.FC<Props> = (props: any) => {
  const [loadingData, setLoadingData] = useState(true);
  const [isVisible, setIsVisible] = useState(props.isVisible);
  const [roleData, setRoleData] = useState<any | null>(null);
  const [allPermission, setAllPermission] = useState<Array<any>>([]);
  const [name, nameChange] = useState("");
  const [permissions, permissionsChange] = useState<Array<any>>([]);

  useEffect(() => {
    getRoleDetail(props.id);
    getPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  useEffect(() => {
    if (roleData !== null && props.id) {
      nameChange(roleData.name);
      permissionsChange(roleData.permissions);
    }
  }, [roleData, props.id]);

  function onModalCancelButton() {
    setIsVisible(false);
  }

  function getRoleDetail(value: any) {
    setLoadingData(true);
    detailRoleEndpoint(value)
      .then((res) => {
        const payload = JSON.parse(res.responseBody.Payload);
        if (payload.success) {
          const body = JSON.parse(payload.body);
          setRoleData(body);
        }
        setLoadingData(false);
      })
      .catch((err: any) => {
        setLoadingData(false);
        alert("Error : " + err);
      });
  }

  function getPermissions() {
    queryAllPermission().then((res) => {
      const payload = JSON.parse(res.responseBody.Payload);
      if (payload.success) {
        const permission = JSON.parse(payload.body);
        setAllPermission(permission);
      }
    });
  }

  function editRoleRequest() {
    const data = {
      name: name,
      permissions: permissions,
    };

    updateRoleEndpoint(data).then((res) => {
      var payload = JSON.parse(res.responseBody.Payload);
      if (payload.success) {
        setIsVisible(false);
        props.onSaveComplete();
      } else {
        //show error
      }
    });
  }

  async function detailRoleEndpoint(params: any) {
    let init = {
      headers: {
        "X-UID": getUidFromCookie(),
      },
    };
    return await API.get("pejuangweb", "/api/role/" + params, init);
  }

  async function queryAllPermission() {
    return API.get("pejuangweb", "/api/permission/", {
      headers: {
        "X-UID": getUidFromCookie(),
      },
    });
  }

  async function updateRoleEndpoint(params: any) {
    let init = {
      body: params,
      headers: {
        "X-UID": getUidFromCookie(),
      },
    };
    return await API.put("pejuangweb", "/api/role/" + props.id, init);
  }

  function renderContent() {
    if (loadingData) {
      return (
        <div>
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    }
    return (
      <div>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={4} className="gutter-row">
            Role ID:
          </Col>
          <Col span={4} className="gutter-row">
            Name:
          </Col>
          <Col span={8} className="gutter-row">
            Permissions:
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={4} className="gutter-row">
            <Input value={props.id} readOnly />
          </Col>
          <Col span={4} className="gutter-row">
            <Input
              value={name}
              onChange={(event: any) => nameChange(event.target.value)}
            />
          </Col>
          <Col span={8} className="gutter-row">
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              value={permissions}
              onChange={(value: any) => permissionsChange(value)}
            >
              {allPermission.map((data: any, index: any) => {
                return (
                  <Option value={data.id} key={data.id} x>
                    {data.id}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <Modal
      width="10"
      style={{ marginLeft: "40px", marginRight: "40px" }}
      title="Role Detail"
      visible={isVisible}
      okText="Update"
      onCancel={() => onModalCancelButton()}
      onOk={() => editRoleRequest()}
    >
      {renderContent()}
    </Modal>
  );
};
